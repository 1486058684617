import React, { useState, useEffect } from "react";
import { LargeSecondaryButton } from "../../FormComponents/StyledButtons";
import styled from "styled-components";
import { SectionHeading, SectionSubHeading } from "../LoginComponents";
import {
  AnimatedComponentProps, AnimatedComponentStatus, handleAnimateShow, handleAnimateHide
} from "../LoginComponents/LoginPageAnimation";
import {useTranslation} from 'react-i18next';

interface NewUserPromoProps {
  isActive: boolean;
  onSignUpRequested: () => void;
}
const NewUserPromo: React.FC<NewUserPromoProps> = (props: NewUserPromoProps): JSX.Element => {
  const [status, setStatus] = useState<AnimatedComponentStatus>(AnimatedComponentStatus.Hidden);
  const {t} = useTranslation();
  
  useEffect(() => {
    if (props.isActive) 
      handleAnimateShow(status, 325, setStatus);
    else handleAnimateHide(status, 425, setStatus);
  }, [props.isActive]);

  return (
    <PromoContentWrapper status={status}>
      <SectionHeading>{t("signup.promo.heading")}</SectionHeading>
      <LargeSecondaryButton
        className="new-user-sign-up-promo-button"
        type="button"
        title={t("signup.promo.btnTitle")}
        style={{ margin: '36px auto 32px'}}
        onClick={props.onSignUpRequested}
      >
        {t("signup.promo.btnText")}
      </LargeSecondaryButton>
      <SectionSubHeading>
      {t("signup.promo.subHeading")}
      </SectionSubHeading>
    </PromoContentWrapper>
  );
}
export default NewUserPromo;

const PromoContentWrapper = styled.div<AnimatedComponentProps>`
  display: ${props => props.status === AnimatedComponentStatus.Hidden ?
    "none" : "block"};
  margin-top: 92px;
  
  transform: ${props => props.status === AnimatedComponentStatus.Visible ?
    "translate(0, 0)" : "translate(0, -60px)"};
  opacity: ${props => props.status === AnimatedComponentStatus.Visible ?
    1.0 : 0.0};
  filter: blur(${props => props.blur ? 5 : 0}px);
  transition: 0.4s;
`;