import React from "react";
import Axios from "axios";
import styled from "styled-components";
import { ConcreteColors, notify } from "hcss-components";
import { useTranslation } from "react-i18next";
import { ITwoFactorAuthModel } from "../Login/LoginComponents/Content/ResetPasswordForm";
import { LinkButton } from "./LinkButton";

type TotpType = "resetpassword" | "2fa";
type TotpProviders = "sms" | "email";

interface IResendTotpButtonProps {
  totpType: TotpType;
  twoFactorAuthModel: ITwoFactorAuthModel;
  username: string
}

export const ResendTotpButton: React.FC<IResendTotpButtonProps> = ({
  totpType,
  twoFactorAuthModel,
  username,
}) => {
  const { t } = useTranslation();

  const [codeSent, setCodeSent] = React.useState(false);
  const [triggered, setTriggered] = React.useState(false);

  const handleResend = () => {
    if (!codeSent && !triggered) {
      setTriggered(true);
      if (!twoFactorAuthModel.phoneNumber) submitResendCode("email");
      else if (!twoFactorAuthModel.email) submitResendCode("sms");
    }
  };
  const submitResendCode = (provider: TotpProviders) => {
    setTriggered(false);
    setCodeSent(true);
    Axios.post(`/auth/token/generate?type=${totpType}&provider=${provider}&username=${username}`)
      .then(res => {})
      .catch(err => notify("danger", t("error"), t("Unable to Resend Code") as string))
      .finally(() => setTimeout(() => setCodeSent(false), 5000));
  };

  if (!twoFactorAuthModel.email && !twoFactorAuthModel.phoneNumber) return null;

  if (triggered)
    return (
      <Info>
        {!!twoFactorAuthModel.email && (
          <ResendButton type="button" onClick={() => submitResendCode("email")}>
            Resend code via Email
          </ResendButton>
        )}
        {!!twoFactorAuthModel.phoneNumber && (
          <ResendButton type="button" onClick={() => submitResendCode("sms")}>
            Resend code via SMS
          </ResendButton>
        )}
      </Info>
    );
  return (
    <Info>
      <ResendButton
        type="button"
        disabled={codeSent}
        onClick={() => handleResend()}
      >
        Resend code
      </ResendButton>
    </Info>
  );
};

export default ResendTotpButton;

const ResendButton = styled(LinkButton)`
  transition: 0.4s;
  margin: 5px 10px !important;
  &:disabled {
    color: ${ConcreteColors.gray600};
    background-color: ${ConcreteColors.gray100};
    cursor: not-allowed;
  }
  &:focus,
  &:active {
    outline: none;
  }
`;

const Info = styled.div`
  font-weight: 400;
  width: 100%;
  color: ${ConcreteColors.gray600};
  text-align: center;
  margin: auto;
  transition: 0.4s;
  @media screen and (max-width: 575px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 1.35rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 1.3rem;
  }
`;
