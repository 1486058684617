import styled from "styled-components";

interface BackgroundProps {
  backgroundColor: string;
}
export const Background = styled.div<BackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => props.backgroundColor};
  z-index: 0;
`;