export enum LoginTypes {
  Username,
  Phone,
}

export interface AntiForgeryModel {
  name: string;
  value: string;
}

export interface IdentityModel {
  subcontractor: boolean;
  redirectToSignup: boolean;
  defaultPhoneLogin: boolean;
  allowedLoginTypes: LoginTypes[];
  isMobile: boolean;
  antiForgery: AntiForgeryModel;
  announcementMsg: string;
  externalAuth: string[];
  returnUrl: string;
  loginUrl: string;
  errorMessage: string | null;
  loginHint: string | null;
}

declare global {
  interface Window {
    identityModel: IdentityModel;
  }
}

class IdentityModelService implements IdentityModel {
  private readonly model: IdentityModel;
  constructor(model: IdentityModel) {
    this.model = model;
  }

  get subcontractor() {
    return this.model.subcontractor;
  }

  get redirectToSignup() {
    return this.model.redirectToSignup;
  }

  get defaultPhoneLogin() {
    return this.model.defaultPhoneLogin;
  }

  get allowedLoginTypes() {
    return this.model.allowedLoginTypes;
  }

  get isMobile() {
    return this.model.isMobile;
  }

  get antiForgery() {
    return this.model.antiForgery;
  }

  get announcementMsg() {
    return this.model.announcementMsg;
  }

  get externalAuth() {
    return this.model.externalAuth;
  }

  get returnUrl() {
    return this.model.returnUrl;
  }

  get loginUrl() {
    return this.model.loginUrl;
  }

  get errorMessage() {
    return this.model.errorMessage;
  }

  get loginHint() {
      return this.model.loginHint;
  }
}

const identityModelService: IdentityModelService = new IdentityModelService(
  JSON.parse(document.scripts['modelJson'].innerText)
);
export default identityModelService;
