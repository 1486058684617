import React, { useState, useEffect } from "react";
import { Button, ConcreteColors } from "hcss-components";
import styled from "styled-components";
import {
  AnimatedComponentStatus,
  AnimatedComponentProps,
  CanBlur,
} from "../LoginPageAnimation";
import { useTranslation } from "react-i18next";
import { LoginTypes } from "./LoginTypes";

const Container = styled.div<AnimatedComponentProps>`
  visibility: ${(props) =>
    props.status === AnimatedComponentStatus.Hidden ? "hidden" : "visible"};

  margin: 10px auto;

  opacity: ${(props) =>
    props.status === AnimatedComponentStatus.Showing ||
    props.status === AnimatedComponentStatus.Visible
      ? 1.0
      : 0.0};

  filter: blur(${(props) => (props.blur ? 5 : 0)}px);
  transition: 0.4s;
  width: calc(100% - 72px);

  /* figure out how to resize these on mobile */
  /* @media screen and (max-width: 575px) and (max-height: 680px) {
        top: 440px;
    }

    @media screen and (max-width: 575px) and (min-height: 681px) and (max-height: 800px) {
        top: 470px;
    }

    @media screen and (max-width: 575px) and (min-height: 801px) {
        top: 515px;
    } */

  > hr {
    margin-top: 36px;
    overflow: visible;
    text-align: center;
    ::after {
      content: "or";
      display: inline-block;
      background: white;
      padding: 0 16px;
      position: relative;
      top: -12px;
      color: ${ConcreteColors.gray500};
    }
  }

  > .button-container {
    display: flex;
    flex-direction: column;
  }
`;

interface Props extends CanBlur {
  toggleLoginType: () => void;
  isActive: boolean;
  isPhoneLogin: boolean;
  /** Label above the username field */
  userNameFieldLabel?: string;
  allowedLoginTypes: LoginTypes[];
}

export const OtherLoginMethods = (props: Props) => {
  const [status, setStatus] = useState(AnimatedComponentStatus.Hidden);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.isActive && props.allowedLoginTypes.length > 1)
      setStatus(AnimatedComponentStatus.Visible);
    else setStatus(AnimatedComponentStatus.Hidden);
  }, [props.allowedLoginTypes, props.isActive]);

  return (
    <Container status={status} blur={props.blur}>
      <div className="button-container">
        <Button
          onClick={props.toggleLoginType}
          hcssStyle="ThemeInverted"
          testId="phone-login-button"
        >
          {props.isPhoneLogin
            ? props.userNameFieldLabel || t("otherLoginMethods.username")
            : t("otherLoginMethods.phoneNumber")}
        </Button>
      </div>
    </Container>
  );
};

export default OtherLoginMethods;
