import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ConcreteColors, DropdownButton, MenuItem } from "hcss-components";
import {
  AnimatedComponentProps,
  AnimatedComponentStatus,
  CanBlur,
} from "../LoginPageAnimation";
import { LinkButton } from "../../../FormComponents/LinkButton";
import { useTranslation } from "react-i18next";

interface LoginSupportLinksProps extends CanBlur {
  /** Determines whether or not the component is visible to user and can be interacted with */
  isActive: boolean;
  /** Callback executed when user requests a password reset */
  onRequestPasswordReset: () => void;
  /** Informs as to whether or not user is currently resetting password */
  userIsResettingPassword: boolean;
  numOfAltLoginMethods: number;
  isMobile: boolean;
}
const LoginSupportLinks: React.FunctionComponent<LoginSupportLinksProps> = (
  props: LoginSupportLinksProps
): JSX.Element => {
  const [status, setStatus] = useState<AnimatedComponentStatus>(
    AnimatedComponentStatus.Hidden
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (props.isActive) setStatus(AnimatedComponentStatus.Visible);
    else setStatus(AnimatedComponentStatus.Hidden);
  }, [props.isActive]);

  const renderForgotPasswordLink = () => {
    if (!props.userIsResettingPassword)
      return (
        <SupportLinkContainer>
          <LinkButton onClick={props.onRequestPasswordReset}>
            {t("forgot")}
          </LinkButton>
        </SupportLinkContainer>
      );
    return <div></div>;
  };

  return (
    <StyledSupportLinks
      className="login-support-links"
      status={status}
      blur={props.blur}
      numOfAltLoginMethods={props.numOfAltLoginMethods}
    >
      {renderForgotPasswordLink()}
      <SupportLinkContainer>
        {!props.isMobile ? (
          <a
            href="https://support.hcss.com/"
            title="Contact HCSS Support"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("support")}
          </a>
        ) : (
          <SupportLinkMobileDropdown
            id="hcss-support-dropdown"
            title={t("support")}
            noCaret
            color={ConcreteColors.blue100}
          >
            <MenuItem>+1 (855) 231-7878</MenuItem>
          </SupportLinkMobileDropdown>
        )}
      </SupportLinkContainer>
    </StyledSupportLinks>
  );
};
export default LoginSupportLinks;

const SupportLinkMobileDropdown = styled(DropdownButton)`
  margin: 0 auto !important;
  border: none;
  padding: 0 8px;
  background-color: transparent;
  color: ${ConcreteColors.blue200} !important;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    border: none;
    background-color: ${ConcreteColors.blue100} !important;
  }
`;

const SupportLinkContainer = styled.div`
  text-align: center;

  & > a {
    color: ${ConcreteColors.blue200};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:focus,
    &:hover,
    &:active {
      color: ${ConcreteColors.blue300};
    }
  }
  .btn-group.open .dropdown-toggle,
  #hcss-support-dropdown.open {
    background-color: ${ConcreteColors.blue100};
    box-shadow: none !important;
  }

  @media screen and (max-width: 464px) {
    display: block;
    flex-basis: 100%;
    margin-bottom: 16px;
  }

  @media screen and (min-width: 465px) {
    display: inline-block;
  }
`;

interface Props extends AnimatedComponentProps {
  numOfAltLoginMethods: number;
}

const StyledSupportLinks = styled.div<Props>`
  display: ${(props) =>
    props.status === AnimatedComponentStatus.Hidden ? "none" : "flex"};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  opacity: ${(props) =>
    props.status === AnimatedComponentStatus.Showing ||
    props.status === AnimatedComponentStatus.Visible
      ? 1.0
      : 0.0};
  filter: blur(${(props) => (props.blur ? 5 : 0)}px);

  z-index: 10;
  transition: color 0.2s, opacity 0.4s, top 0.4s, bottom 0.4s;

  @media screen and (max-width: 464px) {
    flex-wrap: wrap;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 465px) and (max-width: 575px) {
    flex-wrap: nowrap;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    flex-wrap: nowrap;
    font-size: 1.4rem;
  }

  @media screen and (min-width: 992px) {
    flex-wrap: nowrap;
    font-size: 1.4rem;
  }

  @media screen and (max-width: 575px) and (max-height: 680px) {
    top: ${(props) =>
      `${
        464 +
        props.numOfAltLoginMethods * 60 +
        (props.numOfAltLoginMethods === 0 ? 0 : 74)
      }px`};
    bottom: unset;
    margin-top: 60px;
  }

  @media screen and (max-width: 575px) and (min-height: 681px) and (max-height: 800px) {
    top: ${(props) =>
      `${
        500 +
        props.numOfAltLoginMethods * 60 +
        (props.numOfAltLoginMethods === 0 ? 0 : 74)
      }px`};
    bottom: unset;
    margin-top: 140px;
  }

  @media screen and (max-width: 575px) and (min-height: 801px) {
    top: ${(props) =>
      `${
        540 +
        props.numOfAltLoginMethods * 60 +
        (props.numOfAltLoginMethods === 0 ? 0 : 74)
      }px`};
    bottom: unset;
    margin-top: 200px;
  }
`;
