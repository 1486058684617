import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LoadingSpinner } from "./LoadingSpinner";
import {
  AnimatedComponentProps, AnimatedComponentStatus, handleAnimateHide, handleAnimateShow
} from "../LoginComponents/LoginPageAnimation";

interface LoadingOverlayProps {
  isActive: boolean;
}
const LoadingOverlay: React.FunctionComponent<LoadingOverlayProps> =
  (props: LoadingOverlayProps): JSX.Element => {
  const [status, setStatus] = useState<AnimatedComponentStatus>(AnimatedComponentStatus.Hidden);

  useEffect(() => {
    if (props.isActive) handleAnimateShow(status, 20, setStatus);
    else handleAnimateHide(status, 425, setStatus);
  }, [props.isActive]);
  return (
    <StyledLoadingOverlayContainer status={status}>
      <LoadingSpinner
          visible={props.isActive}
          visibleOpacity={0.8}
          background={"#ffffff"}
        />
    </StyledLoadingOverlayContainer>
  );
}
export default LoadingOverlay;

const StyledLoadingOverlayContainer = styled.div<AnimatedComponentProps>`
  display: ${props => props.status === AnimatedComponentStatus.Hidden ?
    "none" : "flex"};
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background: #ffffff;
  opacity: ${props => (props.status === AnimatedComponentStatus.Showing
    || props.status === AnimatedComponentStatus.Visible) ?
    0.9 : 0.0};

  z-index: 100;
  transition: 0.4s;

  @media screen and (max-width: 575px) {
    border-radius: 0;
  }

  @media screen and (min-width: 576px) {
    border-radius: 6px;
  }
`;