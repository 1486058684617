import styled from "styled-components";
import {
  AnimatedComponentProps,
  AnimatedComponentStatus,
} from "../LoginPageAnimation";

interface Props extends AnimatedComponentProps {
  numOfAltLoginMethods: number;
}

export const LoginFormWrapper = styled.div<Props>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${(props) =>
    props.status === AnimatedComponentStatus.Showing ||
    props.status === AnimatedComponentStatus.Visible
      ? 1.0
      : 0.0};
  visibility: ${(props) =>
    props.status === AnimatedComponentStatus.Hidden ? "hidden" : "visible"};
  transition: 0.2s;
  filter: blur(${(props) => (props.blur ? 5 : 0)}px);

  transform: translateZ(0);
  z-index: ${(props) =>
    props.status === AnimatedComponentStatus.Hidden ? 1 : 4};
`;
