import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  LoginPage,
  Container,
  HCSSLogo,
  LoginSupportLinks,
  LoginForm,
  ResetPasswordForm,
  Announcement,
} from "../LoginComponents/";
import LoadingOverlay from "../Loaders/LoadingOverlay";
import { DualLoginSignupPageStatus } from "./DualLoginSignupAnimation";
import {
  AnimatedComponentStatus,
  handleAnimateShow,
} from "../LoginComponents/LoginPageAnimation";
import identityModelService from "../../../services/IdentityModelService";
import NewUserPromo from "./NewUserPromo";
import NewUserForm from "./NewUserForm";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DualLoginSignUp: React.FC = () => {
  const [status, setStatus] = useState<DualLoginSignupPageStatus>(
    DualLoginSignupPageStatus.Initial
  );
  const [newUserFormActive, setNewUserFormActive] = useState<boolean>(false);
  const [isResettingPassword, setIsResettingPassword] =
    useState<boolean>(false);
  const [containerStatus, setContainerStatus] =
    useState<AnimatedComponentStatus>(AnimatedComponentStatus.Hidden);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { t } = useTranslation();
  const containerBackground = "#ffffff";

  useLayoutEffect(() => {
    if (!!identityModelService.redirectToSignup) handleDisplaySignUpFormInitially();
    else handleDefaultInitialDisplay();
  }, [false]);

  useEffect(() => {
    if (status === DualLoginSignupPageStatus.RenderingContainer)
      handleAnimateShow(containerStatus, 10, setContainerStatus);

    if (
      status === DualLoginSignupPageStatus.NewUserFormTransition ||
      status === DualLoginSignupPageStatus.RenderingNewUserForm
    )
      setNewUserFormActive(true);
    else setNewUserFormActive(false);
  }, [status]);

  const enableLoader = () => setShowLoader(true);
  const disableLoader = () => setShowLoader(false);

  const handleDefaultInitialDisplay = () => {
    setTimeout(
      () => setStatus(DualLoginSignupPageStatus.RenderingContainer),
      500
    );
    setTimeout(
      () => setStatus(DualLoginSignupPageStatus.RenderingDualPage),
      720
    );
  };
  const handleDisplaySignUpFormInitially = () => {
    setStatus(DualLoginSignupPageStatus.RenderingNewUserForm);
    setTimeout(() => setContainerStatus(AnimatedComponentStatus.Visible), 500);
  };

  const handlePasswordResetRequest = () => {
    setStatus(DualLoginSignupPageStatus.ResetFormTransition);
    setTimeout(
      () => setStatus(DualLoginSignupPageStatus.RenderingResetForm),
      200
    );
    setIsResettingPassword(true);
  };
  const cancelPasswordResetRequest = () => {
    setStatus(DualLoginSignupPageStatus.ResetFormTransition);
    setTimeout(
      () => setStatus(DualLoginSignupPageStatus.RenderingDualPage),
      200
    );
    setIsResettingPassword(false);
  };

  const handleShowSignUpForm = () => {
    setIsResettingPassword(false);
    setStatus(DualLoginSignupPageStatus.NewUserFormTransition);
    setTimeout(
      () => setStatus(DualLoginSignupPageStatus.RenderingNewUserForm),
      300
    );
  };
  const handleHideSignUpForm = () => {
    setStatus(DualLoginSignupPageStatus.NewUserFormTransition);
    setTimeout(() => {
      setStatus(DualLoginSignupPageStatus.RenderingDualPage);
    }, 300);
  };

  function getClassNameForHeightSpacer(): string {
    let value = "height-spacer";
    if (newUserFormActive) value += " new-user-form-active";
    return value;
  }

  function getPageClassName(): string {
    let value = "login-page";
    value += newUserFormActive ? " new-user-form" : " dual-login-signup";
    return value;
  }
  //
  //
  //
  return (
    <DualLoginSignUpPage className={getPageClassName()}>
      <StyledContainer
        className="container"
        status={containerStatus}
        background={containerBackground}
      >
        <HCSSLogo blur={showLoader} alignLarge="left" />

        <StyledLogInSection
          className="login-section"
          newUserFormActive={newUserFormActive}
        >
        <Announcement model={identityModelService} blur={showLoader} />

          <FormHeightSpacer className={getClassNameForHeightSpacer()}>
            <LoginForm
              model={identityModelService}
              isActive={status === DualLoginSignupPageStatus.RenderingDualPage}
              userNameFieldLabel={t("loginForm.emailOrUsername")}
              heading={t("loginForm.heading")}
              subHeading={t("loginForm.subHeading")}
              useKeepSignedInOption
              useSignUpButton
              onNewUserSignUpRequested={handleShowSignUpForm}
              onLoginRequested={enableLoader}
              blur={showLoader}
              numOfAltLoginMethods={0}
            />

            <ResetPasswordForm
              model={identityModelService}
              onProcessRequestStart={enableLoader}
              onProcessRequestEnd={disableLoader}
              onCancel={cancelPasswordResetRequest}
              blur={showLoader}
              isActive={status === DualLoginSignupPageStatus.RenderingResetForm}
              isMobile={identityModelService.isMobile}
            />
          </FormHeightSpacer>

          {!identityModelService.subcontractor && (
            <LoginSupportLinks
              onRequestPasswordReset={handlePasswordResetRequest}
              userIsResettingPassword={isResettingPassword}
              blur={showLoader}
              isActive={
                status === DualLoginSignupPageStatus.RenderingDualPage ||
                status === DualLoginSignupPageStatus.ResetFormTransition ||
                status === DualLoginSignupPageStatus.RenderingResetForm
              }
              numOfAltLoginMethods={0}
              isMobile={identityModelService.isMobile}
            />
          )}
        </StyledLogInSection>

        <StyledPromoSection className="new-user-promo-section">
          <NewUserPromo
            isActive={
              status === DualLoginSignupPageStatus.RenderingDualPage ||
              status === DualLoginSignupPageStatus.ResetFormTransition ||
              status === DualLoginSignupPageStatus.RenderingResetForm
            }
            onSignUpRequested={handleShowSignUpForm}
          />
        </StyledPromoSection>

        <NewUserForm
          model={identityModelService}
          containerVisible={
            status === DualLoginSignupPageStatus.NewUserFormTransition ||
            status === DualLoginSignupPageStatus.RenderingNewUserForm
          }
          formActive={status === DualLoginSignupPageStatus.RenderingNewUserForm}
          onHideForm={handleHideSignUpForm}
        />

        <LoadingOverlay isActive={showLoader} />
      </StyledContainer>
    </DualLoginSignUpPage>
  );
};
export default DualLoginSignUp;

const DualLoginSignUpPage = styled(LoginPage)`
  &.dual-login-signup {
    @media screen and (max-width: 576px) {
      align-items: flex-start;
    }

    @media screen and (min-width: 576px) and (max-width: 992px) and (max-height: 616px) {
      align-items: flex-start;
      padding: 10px 0;
    }

    @media screen and (min-width: 576px) and (max-width: 992px) and (min-height: 616px) {
      align-items: center;
    }

    @media screen and (min-width: 992px) and (max-height: 676px) {
      align-items: flex-start;
      padding: 10px 0;
    }

    @media screen and (min-width: 992px) and (min-height: 676px) {
      align-items: center;
    }
  }

  &.new-user-form {
    @media screen and (max-width: 576px) {
      align-items: flex-start;
    }

    @media screen and (min-width: 576px) and (max-width: 992px) and (max-height: 986px) {
      align-items: flex-start;
      padding: 10px 0;
    }

    @media screen and (min-width: 576px) and (max-width: 992px) and (min-height: 986px) {
      align-items: center;
    }

    @media screen and (min-width: 992px) and (max-height: 794px) {
      align-items: flex-start;
      padding: 10px 0;
    }

    @media screen and (min-width: 992px) and (min-height: 794px) {
      align-items: center;
    }
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0;

  transform: translateZ(0);

  @media screen and (max-width: 575px) {
    padding: 0;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    padding: 0;
  }

  @media screen and (min-width: 992px) {
    padding: 0;
    width: 948px;
  }
`;

interface StyledLogInSectionProps {
  newUserFormActive: boolean;
}
const StyledLogInSection = styled.div<StyledLogInSectionProps>`
  position: relative;
  height: 100%;

  background: none;

  transform: translateZ(0);

  transition: 0.6s;
  z-index: 1;

  @media screen and (max-width: 575px) {
    width: 100%;
    padding: 36px 40px;
    border-radius: 6px;

    .heading,
    .subheading {
      display: none;
    }
  }

  @media screen and (max-width: 575px) and (max-height: 680px) {
    .login-form-wrapper {
      margin-top: 60px;
      margin-bottom: ${(props) => (props.newUserFormActive ? 580 : 100)}px;
    }

    .login-support-links {
      top: 550px;
    }
  }

  @media screen and (max-width: 575px) and (min-height: 681px) and (max-height: 800px) {
    .login-form-wrapper {
      margin-top: 120px;
      margin-bottom: ${(props) => (props.newUserFormActive ? 612 : 100)}px;
    }

    .login-support-links {
      top: 572px;
    }
  }

  @media screen and (max-width: 575px) and (min-height: 801px) {
    .login-form-wrapper {
      margin-top: 140px;
      margin-bottom: ${(props) => (props.newUserFormActive ? 652 : 100)}px;
    }

    .login-support-links {
      top: 612px;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    width: 100%;
    padding: 36px 40px;
    border-radius: 6px;

    .login-form-wrapper {
      margin-bottom: ${(props) => (props.newUserFormActive ? 432 : 80)}px;
    }

    .heading,
    .subheading {
      display: none;
    }
  }

  @media screen and (min-width: 992px) {
    display: inline-block;
    width: 50%;
    padding: 36px 48px;
    flex-shrink: 0;
    border-radius: 6px 0 0 6px;

    .login-form-wrapper {
      margin-bottom: ${(props) => (props.newUserFormActive ? 236 : 116)}px;
    }

    .signup-button {
      display: none;
    }
  }
`;

const StyledPromoSection = styled.div`
  position: relative;
  padding: 36px 48px;
  background: none;

  transform: translateZ(0);
  z-index: 3;

  @media screen and (max-width: 991px) {
    display: none;
    border-radius: 0 0 0 0;
  }

  @media screen and (min-width: 992px) {
    display: inline-block;
    width: 50%;
    flex-grow: 1;
    border-radius: 0 6px 6px 0;
  }
`;

const FormHeightSpacer = styled.div`
  position: relative;
  min-height: 310px;
  transition: 0.6s;

  &.new-user-form-active {
    min-height: 586px;
  }
  @media screen and (max-width: 992px) {
    &.new-user-form-active {
      min-height: 850px;
    }
  }
  @media screen and (max-width: 575px) {
    &.new-user-form-active {
      min-height: 872px;
      & > .login-form-wrapper {
        margin-top: 0;
      }
    }
  }
`;
