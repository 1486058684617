export enum LoginPageDisplayStatus {
  Initial,
  RenderingContainer,
  RenderingLoginForm,
  RenderingResetForm,
  FormTransition,
  RenderingPhoneLoginForm
}

export enum AnimatedComponentStatus {
  Hidden,
  Hiding,
  Showing,
  Visible
}

export interface AnimatedComponentProps {
  status: AnimatedComponentStatus;
  blur?: boolean;
}

export interface CanBlur {
  blur: boolean;
}

export function handleAnimateShow(
  currentStatus: AnimatedComponentStatus,
  delay: number,
  callback: React.Dispatch<React.SetStateAction<AnimatedComponentStatus>>
) {
  if (currentStatus !== AnimatedComponentStatus.Visible) {
    callback(AnimatedComponentStatus.Showing);
    setTimeout(() => callback(AnimatedComponentStatus.Visible), delay);
  }
}

export function handleAnimateHide(
  currentStatus: AnimatedComponentStatus,
  delay: number,
  callback: React.Dispatch<React.SetStateAction<AnimatedComponentStatus>>
) {
  if (currentStatus !== AnimatedComponentStatus.Hidden) {
    callback(AnimatedComponentStatus.Hiding);
    setTimeout(() => callback(AnimatedComponentStatus.Hidden), delay);
  }
}