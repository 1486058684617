import React, { useState, useEffect, useRef } from "react";
import { StyledFormGroup, StyledHelpBlock } from "./StyledFormComponents";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ConcreteColors, Checkbox } from "hcss-components";

interface TermsAndConditionsControlProps {
  name: string;
  checked: boolean;
  handleTCClick: () => void;
  handleChange: (e: any) => void;
  validationState?: "success" | "warning" | "error" | null;
  message?: string;
  visible?: boolean;
  focus?: boolean;
}

const TermsAndConditionsControl: React.FC<TermsAndConditionsControlProps> = ({
  visible = true,
  ...props
}) => {
  const [isLinkClicked, setIsLinkClicked] = useState<boolean>(false);
  const { t } = useTranslation();
  const serviceAgreementUrl = "https://www.hcss.com/hcss-services-agreement/";
  const privacyPolicyUrl = "https://www.hcss.com/privacy-policy/";

  const inputRef = useRef<HTMLInputElement & any>();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.enterKeyHint = "done";
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (visible && props.focus)
      inputRef && inputRef.current && inputRef.current.focus();
  }, [visible, props.focus]);

  const handleTCClick = () => {
    setIsLinkClicked(true);
    props.handleTCClick();
  };

  const openLinkInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer"); 
  }

  return (
    <StyledFormGroup
      id={`${props.name}-formgroup`}
      focused={true}
      validationState={props.validationState}
      style={{ textAlign: "left", marginBottom: "16px" }}
    >
      <Checkbox
        name={props.name}
        checked={props.checked}
        value={props.checked ? "true" : "false"}
        onChange={props.handleChange}
      />
      <div style={{textAlign: "center", fontSize: ".7em", display: "inline"}}>
        {t("signup.form.termsAndConditions.checkBoxLabel1")}
        <StyledLinkButton
          data-testid="terms-and-conditions-link-btn"
          onClick={() => openLinkInNewTab(serviceAgreementUrl)}
          isLinkClicked={isLinkClicked}
        >
          {t("signup.form.termsAndConditions.hcssServicesAgreement")}
        </StyledLinkButton>
        {t("signup.form.termsAndConditions.checkBoxLabel2")}
        <StyledLinkButton
          data-testid="terms-and-conditions-link-btn"
          onClick={() => openLinkInNewTab(privacyPolicyUrl)}
          isLinkClicked={isLinkClicked}
        >
          {t("signup.form.termsAndConditions.hcssPrivacyPolicy")}
        </StyledLinkButton>
        {t("signup.form.termsAndConditions.checkBoxLabel3")}
      </div>
    
      {props.message && <StyledHelpBlock>{props.message}</StyledHelpBlock>}
    </StyledFormGroup>
  );
};

export default TermsAndConditionsControl;

interface LinkButtonProps {
  isLinkClicked: boolean;
}
const StyledLinkButton = styled.button<LinkButtonProps>`
  color: ${props => (props.isLinkClicked ? "purple" : ConcreteColors.blue200)};
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 15px;
  transition: background-color 0.2s;
  margin: 0 !important;
  :hover {
    background-color: ${ConcreteColors.blue100};
    cursor: pointer;
  }
`;
