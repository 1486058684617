import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { ConcreteColors, Icon, Overlay, Popover } from "hcss-components";

export interface SupportActionsProps {
    onRequestSupportLogin: () => void;
}

const SupportActions: React.FC<SupportActionsProps> = (props: SupportActionsProps) => {
    // Portal container and mounting-unmounting functionality
    const [container, setContainer] = useState<HTMLElement | null>();

    useEffect(()=>{
        const containerElement = document.querySelector(".login-page");
        setContainer((containerElement as HTMLElement) || null);

        return ()=>{
            setContainer(null);
        };
    }, [])

    const tooltipTriggerRef = useRef<HTMLButtonElement | null>(null);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const { onRequestSupportLogin } = props;

    function toggleTooltip() {
        setTooltipOpen(state => !state);
    }

    function closeTooltip() {
        setTooltipOpen(false);
    }

    function onClickSupportLogin() {
        onRequestSupportLogin();
        closeTooltip();
    }

    const renderContent = () => (
        <>
            <StyledTooltipTrigger onClick={toggleTooltip} ref={tooltipTriggerRef}>
                <Icon name="ellipsis-v" />
            </StyledTooltipTrigger>
            <Overlay
                show={tooltipOpen}
                target={tooltipTriggerRef.current || undefined}
                placement="bottom"
                rootClose
                onHide={closeTooltip}
            >
                <StyledTooltip
                    id="support-actions-tooltip"
                    data-testid="support-actions-tooltip"
                >
                    <SupportActionsMenu className="support-actions-menu">
                        <li className="support-actions-menu-item">
                            <button className="support-login-btn" onClick={onClickSupportLogin}>
                                HCSS Support Log In
                            </button>
                        </li>
                    </SupportActionsMenu>
                </StyledTooltip>
            </Overlay>
        </>
    );

    if (container) return createPortal(renderContent(), container);
    return null;
}

export default SupportActions;

const StyledTooltipTrigger = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    top: 16px;
    right: 16px;

    background: rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 80px;
    box-shadow: none;
    color: ${ConcreteColors.gray500};
    cursor: pointer;
    font-size: 1.5rem;
    overflow: hidden;
    transition: 0.2s;
    z-index: 200;

    &,
    &:focus,
    &:active:focus {
        outline: none;
    }

    &:hover,
    &:active,
    &:active:hover,
    &:active:focus {
        background: rgba(0, 0, 0, 0.05);
        color: ${ConcreteColors.blue200};
    }
`;

const StyledTooltip = styled(Popover)`
  margin-top: 8px !important;
  padding: 0 !important;
  background: #ffffff;
  border: 0;
  box-shadow: 0 0 6px -3px ${ConcreteColors.gray500};

  & > .popover-content {
    padding: 12px 16px;
    color: ${ConcreteColors.gray600};
    font-family: "Inter", Arial, Helvetica, sans-serif !important;
    font-size: 1.3rem;
  }
`;

const SupportActionsMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  
  & > li {
    display: "block";
    margin: 0 -16px;
    padding: 3px 16px;

    background: none;
    color: ${ConcreteColors.gray600};
    cursor: pointer;
    transition: 0.2s;

    &,
    &:focus {
      outline: none;
    }

    &:hover,
    &:active,
    &:active:hover,
    &:active:focus {
      background: ${ConcreteColors.blue100};
      color: ${ConcreteColors.blue200};
    }

    & > button {
        background: none;
        border: 0;
        box-shadow: none;
        color: inherit;
    
        &,
        &:focus,
        &:active:focus {
            outline: none;
        }
    }
  }
`;