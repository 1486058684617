import { ConcreteColors } from "hcss-components";
import styled from "styled-components";

export const LargeButton = styled.button`
  display: block;
  position: relative;
  width: 100%;
  max-width: 320px;
  padding: 10px 24px;
  overflow: hidden;

  border-radius: 200px;
  outline: none;

  font-size: 1.35rem;
  font-weight: 800;
  text-transform: uppercase;

  transition: 0.3s;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
  
  @media screen and (max-width: 575px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 1.35rem;
  }
`;

export const LargePrimaryButton = styled(LargeButton)`
  background: ${ConcreteColors.blue200};
  border: 1px solid ${ConcreteColors.blue200};
  color: #ffffff;

  &:hover,
  &:active,
  &:focus {
    background: ${ConcreteColors.blue300};
  }
  &:disabled {
    background: ${ConcreteColors.gray500};
  }
`;

export const LargeSecondaryButton = styled(LargeButton)`
  background: #ffffff;
  border: 1px solid ${ConcreteColors.blue200};
  color: ${ConcreteColors.blue200};

  &:hover,
  &:active,
  &:focus {
    background: ${ConcreteColors.blue100};
    border: 1px solid ${ConcreteColors.blue300};
    color: ${ConcreteColors.blue300};
  }
`;