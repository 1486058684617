import React from "react";
import styled from "styled-components";
import { CanBlur } from "../LoginPageAnimation";

interface HCSSLogoProps extends CanBlur {
  /** Alignment of logo at small screen widths (less than 576 pixels in width). Defaults to "center". */
  alignSmall?: "center" | "left";
  /** Alignment of logo at medium screen widths (between 576 and 1024 pixels in width). Defaults to "center". */
  alignMedium?: "center" | "left";
  /** Alignment of logo at larger screen widths (greater than 1024 pixels in width). Defaults to "center". */
  alignLarge?: "center" | "left";
}
const HCSSLogo: React.FunctionComponent<HCSSLogoProps> = (
  props: HCSSLogoProps
): JSX.Element => {
  return (
    <StyledLogoSVG
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="190 220 220 160"
      enable-background="new 190 220 220 160"
      xmlSpace="preserve"
      blur={props.blur}
      alignSmall={props.alignSmall || "center"}
      alignMedium={props.alignMedium || "center"}
      alignLarge={props.alignLarge || "center"}
    >
      <g>
        <path
          fill="#009639"
          d="M198.2,371.6c0.6-1.9,1.2-3.9,1.7-5.8c6-23.3,11.9-46.5,17.9-69.8c2.7-10.5,5.4-20.9,8-31.4
                    c0.3-1.1,0.8-1.6,1.8-2c8-3,16-6.1,24.3-9.3c-4.6,19.5-9.2,38.8-13.9,58.4c0.6-0.3,0.9-0.4,1.2-0.6c9.1-5.5,18.7-10.2,28.5-14.5
                    c0.8-0.3,1.2-0.8,1.5-1.7c3.7-14.5,7.4-29.1,11.2-43.6c0.1-0.5,0.6-1.2,1-1.4c8-3.1,16.1-6.2,24.1-9.3c0.2-0.1,0.4-0.1,0.8-0.2
                    c-3.6,15.1-7.1,30-10.7,45.1c0.5-0.1,0.8-0.1,1.1-0.3c14.1-4.9,28.5-9.1,43.1-12.3c9.2-2,18.5-3.7,27.8-4.8
                    c8.6-1,17.1-1.4,25.7-1.1c2.6,0.1,5.2,0.4,8,0.6c-0.3,1.5-0.6,2.9-0.9,4.2c-1.2,5.3-2.4,10.7-3.5,16c-0.2,0.7-0.4,0.9-1.2,1
                    c-5.9,0-11.8-0.1-17.6,0.2c-6.8,0.4-13.5,1.2-20.2,2.1c-12.6,1.6-25,4.4-37.2,7.7c-10,2.7-19.8,5.8-29.5,9.3
                    c-0.7,0.3-1.1,0.6-1.3,1.4c-3.2,13.7-6.5,27.3-9.8,41c-0.2,0.8-0.5,1.1-1.3,1.3c-8.3,2.3-16.6,4.6-24.9,6.9
                    c-0.3,0.1-0.7,0.1-1.2,0.2c3.3-12.9,6.6-25.6,9.9-38.8c-1.8,0.9-3.3,1.7-4.8,2.4c-8.9,4.6-17.4,9.6-25.4,15.6
                    c-0.5,0.4-1,1.1-1.1,1.7c-1.9,7.8-3.7,15.6-5.6,23.4c-0.2,0.9-0.6,1.3-1.5,1.6c-8.7,2.4-17.4,4.8-26.1,7.2
                    C198.2,371.9,198.2,371.7,198.2,371.6z"
        />
        <path
          fill="#009639"
          d="M392.4,229.2c0.6,0.2,1.2,0.3,1.8,0.5c4.9,1.3,6.5,5.3,6,9.7c0,0.2-0.4,0.6-0.7,0.6c-1.7,0.1-3.4,0-5.2,0
                    c-0.1-0.4-0.1-0.8-0.1-1.2c-0.1-1.8-1-2.9-2.7-3.4c-2.4-0.7-4.8-0.6-6.9,0.9c-0.7,0.5-1.4,1.5-1.7,2.4c-0.7,1.9,0,3.2,1.9,3.8
                    c2.5,0.8,5,1.5,7.5,2.2c5.8,1.7,7.5,4.7,5.8,10.5c-1.5,5.1-5.7,8.7-11.2,9.2c-2.1,0.2-4.3,0.2-6.4-0.2c-5.4-1-7.9-5.1-6.7-10.6
                    c1.9,0,3.8,0,5.7,0c0.1,0.1,0.1,0.2,0.1,0.2c0.2,3.5,1.2,4.8,4.7,5c1.7,0.1,3.5-0.3,5.2-0.8c0.8-0.2,1.5-0.9,2-1.6
                    c1.7-2.3,1.1-4.4-1.5-5.3c-2.2-0.8-4.4-1.3-6.7-2c-0.8-0.2-1.6-0.5-2.4-0.8c-3.5-1.4-4.9-3.9-4.3-7.6c1-6.5,4.6-10.1,11.1-11.3
                    c0.2,0,0.4-0.2,0.5-0.2C389.7,229.2,391,229.2,392.4,229.2z"
        />
        <path
          fill="#009639"
          d="M363.5,243.1c0-0.5,0-0.9-0.1-1.2c-0.1-1.7-0.9-2.9-2.6-3.4c-2.3-0.8-4.6-0.7-6.7,0.6
                    c-0.7,0.5-1.4,1.2-1.8,2c-1.1,2.2-0.3,3.8,2,4.5c2.7,0.8,5.4,1.5,8.1,2.4c2.7,0.8,5,2.2,5.3,5.3c0.4,4.5-1,8.5-4.7,11.2
                    c-4.8,3.6-10.3,4-15.8,2c-3.6-1.3-5.5-5.5-4.5-9.2c0.1-0.3,0.5-0.7,0.7-0.7c1.7-0.1,3.4,0,5.2,0c0,0.3,0.1,0.5,0.1,0.8
                    c0.1,2.7,1.2,4.2,3.8,4.4c1.8,0.1,3.6-0.1,5.3-0.4c1.4-0.2,2.4-1.2,3.1-2.5c1.1-2.2,0.5-3.9-1.8-4.8c-1.8-0.7-3.7-1.1-5.6-1.7
                    c-1-0.3-1.9-0.5-2.9-0.8c-4.2-1.5-5.7-4.1-4.9-8.5c1.3-7,6.4-11,13.9-10.8c1,0,2,0.1,3,0.3c5.4,1.2,7,5.3,6.6,9.6
                    c0,0.3-0.5,0.8-0.9,0.8C367,243.2,365.3,243.1,363.5,243.1z"
        />
        <path
          fill="#009639"
          d="M339.9,250.7c-2,0-3.9,0-6,0c-0.1-0.3-0.2-0.6-0.2-0.9c-0.4-4.2-3.6-5.2-6.9-4.6c-2,0.4-3.6,1.4-4.8,3
                    c-3.4,4.5-4.6,9.5-3.9,15c0.7,5.6,5.8,6.1,9.2,4.3c1.8-1,3.1-2.6,3.9-4.4c0.3-0.7,0.7-0.9,1.4-0.9c1.7,0.1,3.3,0,5,0
                    c-1.6,5.5-6.4,10.9-12.2,12c-2.4,0.4-4.7,0.5-7-0.3c-4.3-1.4-6.4-4.6-6.8-8.8c-0.7-7.4,0.9-14.3,5.7-20.2c4-5,10.5-7,16.1-5.2
                    C338.1,241,341,246,339.9,250.7z"
        />
      </g>
    </StyledLogoSVG>
  );
};
export default HCSSLogo;

const StyledLogoSVG = styled.svg<HCSSLogoProps>`
  display: block;
  position: absolute;
  filter: blur(${(props) => (props.blur ? 5 : 0)}px);
  z-index: 8;
  transition: 0.3s;

  @media screen and (max-width: 575px) {
    left: ${(props) =>
      props.alignSmall === "center" ? "calc(50% - 28px)" : "36px"};
    width: 56px;
    height: 38px;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    top: 32px;
    left: ${(props) =>
      props.alignMedium === "center" ? "calc(50% - 26px)" : "36px"};
    width: 52px;
    height: 35px;
  }

  @media screen and (min-width: 992px) {
    top: 32px;
    left: ${(props) =>
      props.alignLarge === "center" ? "calc(50% - 24px)" : "36px"};
    width: 48px;
    height: 33px;
  }

  @media screen and (max-width: 575px) and (max-height: 680px) {
    top: 48px;
  }

  @media screen and (max-width: 575px) and (min-height: 681px) and (max-height: 800px) {
    top: 60px;
  }

  @media screen and (max-width: 575px) and (min-height: 801px) {
    top: 80px;
  }
`;
