import React, { useState, useEffect, useRef } from "react";
import { LargePrimaryButton } from "../../FormComponents/StyledButtons";
import PasswordControl from "../../FormComponents/PasswordControl";
import TextControl from "../../FormComponents/TextControl";
import useForm from "../../Hooks/useForm";
import useEmailVerification, {
  EmailAvailability,
} from "../../Hooks/useEmailVerification";
import styled from "styled-components";
import {
  AnimatedComponentStatus,
  handleAnimateHide,
  handleAnimateShow,
  AnimatedComponentProps,
} from "../LoginComponents/LoginPageAnimation";
import { LoadingSpinner } from "../Loaders/LoadingSpinner";
import { SectionHeading, SectionSubHeading } from "../LoginComponents";
import {
  ConcreteColors,
  Row,
  Col,
  Icon,
  Button,
  notify,
} from "hcss-components";
import { validate } from "./validate";
import axios from "axios";
import { useTranslation } from "react-i18next";
import TermsAndConditionsControl from "../../FormComponents/TermsAndConditionsControl";
import TermsAndConditionsModal from "./TermsAndConditionsModal";

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: string;
}
export interface SignUpFormFields<T> {
  firstName: T;
  lastName: T;
  companyName: T;
  email: T;
  password: T;
  confirmPassword: T;
  termsAndConditions: T;
}

interface NewUserFormProps {
  /** JSON containing tokens from IdentityServer */
  model: any;
  /** Determines whether or not the section container is visible, overlaid on top of the login form */
  containerVisible: boolean;
  /** Determines whether or not form is visible to user */
  formActive: boolean;
  /** Callback triggered when user requests to return to the dual login / signup screen */
  onHideForm: () => void;
}
const NewUserForm: React.FC<NewUserFormProps> = (props: NewUserFormProps) => {
  const [containerStatus, setContainerStatus] =
    useState<AnimatedComponentStatus>(AnimatedComponentStatus.Hidden);
  const [status, setStatus] = useState<AnimatedComponentStatus>(
    AnimatedComponentStatus.Hidden
  );
  const [loaderMessage, setLoaderMessage] = useState<string>("");
  const [isProcessingSignUp, setIsProcessingSignUp] = useState<boolean>(false);
  const [isTCModalOpen, setIsTCModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const loginFormRef = useRef<HTMLFormElement | null>(null); //Ref so we can auto-submit a login form after signup

  const handleEmailChange = (event: any) => {
    handleChange(event);
    setEmail(event.target.value);
  };

  const handleCreateAccountRequested = () => {
    if (!isProcessingSignUp) {
      setLoaderMessage(t("signup.form.loading.emailBody"));
      setIsProcessingSignUp(true);
      checkEmailAvailability()
        .then(() => {
          // ...if email address is available:
          setLoaderMessage(t("signup.form.loading.creatingBody"));
          handleCreateAccount(values);
        })
        .catch(() => {
          // ... if email address isn't available:
          setIsProcessingSignUp(false);
        });
    }
  };

  const handleCreateAccount = (values: SignUpFormValues) => {
    var formData = new FormData();
    formData.append("FirstName", values.firstName);
    formData.append("LastName", values.lastName);
    formData.append("CompanyName", values.companyName);
    formData.append("Email", values.email);
    formData.append("Password", values.password);
    formData.append(
      "__RequestVerificationToken",
      props.model.antiForgery.value
    );

    axios
      .post("/Account/SubcontractorSignUp", formData)
      .then(response => {
        if (response.data.success) {
          loginFormRef.current!.submit(); //If signin was successful, auto-login
        } else {
          notify(
            "danger",
            t("error"),
            t("signup.formErrors.general") as string,
            response.data.errorMessage
          );
        }
      })
      .catch(error => {
        setIsProcessingSignUp(false);
        notify("danger", t("error"), t("signup.formErrors.general") as string);
      });
  };

  const validateForm = (values: SignUpFormFields<any>) => {
    return validate({
      values,
      isEmailAvailable,
    });
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    handleReset,
    revalidate,
    setFieldValue,
  } = useForm<SignUpFormFields<any>>({
    onSubmit: handleCreateAccountRequested,
    validate: validateForm,
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  const handleAgreeAndClose = () => {
    setFieldValue("termsAndConditions", true, true);
    setIsTCModalOpen(false);
  };

  const { setEmail, checkEmailAvailability, isEmailAvailable } =
    useEmailVerification({
      model: props.model,
    });

  const renderProcessingSignUpMessage = () => {
    if (isProcessingSignUp)
      return (
        <ProcessingSignUpMessage>
          <SectionHeading>{t("signup.form.loading.heading")}</SectionHeading>
          <SectionSubHeading>{loaderMessage}</SectionSubHeading>
          <div className="loading-spinner-container">
            <LoadingSpinner visible={isProcessingSignUp} />
          </div>
        </ProcessingSignUpMessage>
      );
    return null;
  };

  useEffect(() => {
    if (props.containerVisible)
      handleAnimateShow(containerStatus, 20, setContainerStatus);
    else handleAnimateHide(containerStatus, 625, setContainerStatus);
  }, [props.containerVisible]);

  useEffect(() => {
    if (props.formActive) handleAnimateShow(status, 20, setStatus);
    else handleAnimateHide(status, 625, setStatus);
  }, [props.formActive]);

  useEffect(() => {
    if (isProcessingSignUp) {
      // Scroll to top
      const mainContainer = document.querySelector("main");
      if (mainContainer) mainContainer.scrollTop = 0;
    }
  }, [isProcessingSignUp]);

  useEffect(revalidate, [isEmailAvailable]);
  useEffect(handleReset, [props.containerVisible]);

  return (
    <NewUserFormSection className="new-user-signup" status={containerStatus}>
      {renderProcessingSignUpMessage()}

      <TermsAndConditionsModal
        show={isTCModalOpen}
        onHide={() => setIsTCModalOpen(false)}
        onAgreeAndClose={handleAgreeAndClose}
      />
      <NewUserFormWrapper status={status}>
        <Button
          bsStyle="link"
          className="back-to-login-button"
          onClick={props.onHideForm}
          title={t("signup.form.back")}
        >
          <Icon name="angle-left" />
          {t("signup.form.back")}
        </Button>
        <SectionHeading>{t("signup.form.heading")}</SectionHeading>
        <StyledSectionSubHeading>
          {t("signup.form.subHeading")}
        </StyledSectionSubHeading>

        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <TextControl
                name="firstName"
                label={t("signup.form.fName")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.firstName}
                message={
                  touched.firstName && errors.firstName
                    ? t(errors.firstName)
                    : undefined
                }
                validationState={
                  touched.firstName && errors.firstName ? "error" : undefined
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <TextControl
                name="lastName"
                label={t("signup.form.lName")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.lastName}
                message={
                  touched.lastName && errors.lastName
                    ? t(errors.lastName)
                    : undefined
                }
                validationState={
                  touched.lastName && errors.lastName ? "error" : undefined
                }
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <TextControl
                name="companyName"
                label={t("signup.form.coName")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.companyName}
                message={
                  touched.companyName && errors.companyName
                    ? t(errors.companyName)
                    : undefined
                }
                validationState={
                  touched.companyName && errors.companyName
                    ? "error"
                    : undefined
                }
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <TextControl
                name="email"
                label={t("signup.form.email")}
                handleChange={handleEmailChange}
                handleBlur={handleBlur}
                value={values.email}
                message={
                  (values.email &&
                    isEmailAvailable === EmailAvailability.Unavailable) ||
                  (touched.email && errors.email)
                    ? t(errors.email)
                    : t("signup.form.emailExplanation")
                }
                validationState={
                  (values.email &&
                    isEmailAvailable === EmailAvailability.Unavailable) ||
                  (touched.email && errors.email)
                    ? "error"
                    : undefined
                }
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <PasswordControl
                name="password"
                label={t("signup.form.password")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                message={
                  touched.password && errors.password
                    ? t(errors.password)
                    : undefined
                }
                validationState={
                  touched.password && errors.password ? "error" : undefined
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <PasswordControl
                name="confirmPassword"
                label={t("signup.form.confirmPassword")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.confirmPassword}
                message={
                  touched.confirmPassword && errors.confirmPassword
                    ? t(errors.confirmPassword)
                    : undefined
                }
                validationState={
                  touched.confirmPassword && errors.confirmPassword
                    ? "error"
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <TermsAndConditionsControl
                name="termsAndConditions"
                handleChange={handleChange}
                checked={values.termsAndConditions}
                handleTCClick={() => setIsTCModalOpen(true)}
                message={
                  touched.termsAndConditions && errors.termsAndConditions
                    ? t(errors.termsAndConditions)
                    : undefined
                }
                validationState={
                  touched.termsAndConditions && errors.termsAndConditions
                    ? "error"
                    : undefined
                }
              />
            </Col>
          </Row>

          <LargePrimaryButton
            className="new-user-create-account-button"
            type="submit"
            title={t("signup.form.btnTitle")}
          >
            {t("signup.form.btnText")}
          </LargePrimaryButton>
        </form>
        <form
          name="autologin"
          method="POST"
          action={props.model.loginurl}
          ref={loginFormRef}
        >
          <input type="hidden" name="username" value={values.email} />
          <input type="hidden" name="password" value={values.password} />
          <input
            type="hidden"
            name={props.model.antiForgery.name}
            value={props.model.antiForgery.value}
          />
        </form>
      </NewUserFormWrapper>
    </NewUserFormSection>
  );
};
export default NewUserForm;

const NewUserFormSection = styled.div<AnimatedComponentProps>`
  display: block;
  position: absolute;
  top: -5%;
  right: 0;
  height: 110%;

  border-radius: ${props =>
    props.status === AnimatedComponentStatus.Showing ||
    props.status === AnimatedComponentStatus.Visible
      ? "6px"
      : "0 6px 6px 0"};

  transform: translateZ(0);
  z-index: 1;

  transition: width 0.6s, background 0.8s;

  @media screen and (max-width: 575px) {
    width: 100%;
    height: 110%;

    background: none;

    z-index: ${props =>
      props.status === AnimatedComponentStatus.Visible ? 4 : 0};
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    width: 100%;

    background: none;

    z-index: ${props =>
      props.status === AnimatedComponentStatus.Visible ? 4 : 0};
  }

  @media screen and (min-width: 992px) {
    width: ${props =>
      props.status === AnimatedComponentStatus.Showing ||
      props.status === AnimatedComponentStatus.Visible
        ? 100
        : 50}%;

    background: ${props =>
      props.status === AnimatedComponentStatus.Showing ||
      props.status === AnimatedComponentStatus.Visible
        ? "#ffffff"
        : ConcreteColors.gray200};

    z-index: ${props =>
      props.status === AnimatedComponentStatus.Visible ? 4 : 2};
  }
`;

const NewUserFormWrapper = styled.div<AnimatedComponentProps>`
  display: "block";
  position: absolute;

  opacity: ${props =>
    props.status === AnimatedComponentStatus.Showing ||
    props.status === AnimatedComponentStatus.Visible
      ? 1.0
      : 0.0};
  visibility: ${props =>
    props.status === AnimatedComponentStatus.Hidden ? "hidden" : "visible"};

  transition: 0.6s;

  & > button.back-to-login-button {
    margin: 0 0 32px;
    padding: 0;
    background: none;
    color: ${ConcreteColors.blue200};

    &:hover,
    &:active {
      color: ${ConcreteColors.blue300};
    }

    & > i {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 575px) {
    width: calc(100% - 96px);
    left: 48px;
    height: 20px;
  }
  @media screen and (max-width: 464px) {
    top: 104px;
  }

  @media screen and (max-width: 575px) and (max-height: 680px) {
    top: 104px;
  }

  @media screen and (max-width: 575px) and (min-height: 681px) and (max-height: 800px) {
    top: 140px;
  }

  @media screen and (max-width: 575px) and (min-height: 801px) {
    top: 172px;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    width: calc(100% - 96px);
    top: 70px;
    left: 48px;
  }

  @media screen and (min-width: 992px) {
    width: 480px;
    top: 45px;
    left: ${props =>
      props.status === AnimatedComponentStatus.Showing ||
      props.status === AnimatedComponentStatus.Visible
        ? "calc(50% - 240px)"
        : "calc(50% - 180px)"};
  }
`;

const StyledSectionSubHeading = styled(SectionSubHeading)`
  margin: 6px 0 36px;
`;

const StyledInfo = styled.p`
  margin-top: 24px;
  color: ${ConcreteColors.gray500};
  font-size: 1.3rem;
  text-align: center;
`;

const ProcessingSignUpMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: #ffffff;
  border-radius: 6px;

  z-index: 2;

  & > .loading-spinner-container {
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: 64px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 240px;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    padding-top: 240px;
  }

  @media screen and (min-width: 992px) {
    padding-top: 216px;
  }
`;
