import React, { useEffect, useRef, useState } from "react";
import {
  StyledFormGroup,
  StyledControlLabel,
  StyledFormControl,
  StyledHelpBlock,
} from "./StyledFormComponents";
import { FormControlProps } from "./FormControlProps";

interface TextControlProps extends FormControlProps {
  hide?: boolean;
  enterKeyHint?: string;
  inputMode?: string;
}

const TextControl: React.FC<TextControlProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFieldFocused = () => setIsFocused(true);

  const inputRef = useRef<HTMLInputElement & any>();

  const handleFieldBlurred = (event: any) => {
    setIsFocused(false);
    props.handleBlur(event);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.enterKeyHint = props.enterKeyHint || "";
      inputRef.current.inputMode = props.inputMode || "";
    }
  }, [inputRef.current]);

  return (
    <StyledFormGroup
          style={{
              position: props.hide ? "absolute" : undefined,
              left:- 1000000,
              top: -1000000,
           }}
      id={`${props.name}-formgroup`}
      focused={isFocused}
      validationState={props.validationState}
    >
      <StyledControlLabel>{props.label}</StyledControlLabel>
      <StyledFormControl
        className="user-input"
        type="text"
        onChange={props.handleChange}
        inputRef={(input) => (inputRef.current = input)}
        onFocus={handleFieldFocused}
        onBlur={handleFieldBlurred}
        name={props.name}
        value={props.value}
      />
      {props.message && <StyledHelpBlock>{props.message}</StyledHelpBlock>}
    </StyledFormGroup>
  );
};

export default TextControl;
