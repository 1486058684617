import styled from 'styled-components';
import {ConcreteColors} from 'hcss-components';

export const LinkButton = styled.button`
  color: ${ConcreteColors.blue200};
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 15px;
  transition: background-color .2s;
  margin: 0!important;
  :hover{
      background-color: ${ConcreteColors.blue100};
      cursor: pointer;
  }
`