import styled from "styled-components";
import { AnimatedComponentProps, AnimatedComponentStatus } from "../LoginPageAnimation";

interface LoginContentContainerProps extends AnimatedComponentProps {
  background: string;
}

export const Container = styled.div<LoginContentContainerProps>`
  position: relative;
  min-height: fit-content;

  opacity: ${props => (props.status === AnimatedComponentStatus.Showing
    || props.status === AnimatedComponentStatus.Visible) ?
    1.0 : 0.0};

  z-index: 3;

  & button {
    margin: 16px auto;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    min-height: 90vh;
    margin-top: 0;
    padding: 24px 28px 28px 28px;
    
    background: #ffffff;
    border-radius: 0;
    transition: box-shadow 1.2s, opacity 0.6s, margin-top 0.6s, padding 0.1s, width 0.1s, height 0.0s;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    width: 480px;
    margin: 140px 0;
    padding: 28px 32px 32px 32px;

    background: ${props => props.background};
    border-radius: 6px;
    transition: all 0.6s, box-shadow 1.2s, opacity 0.6s, margin-top 0.6s, padding 0.1s, width 0.1s, height 0.4s;
  }

  @media screen and (min-width: 992px) {
    width: 480px;
    margin: 180px 0;
    padding: 32px 36px 36px 36px;
    
    background: ${props => props.background};
    border-radius: 6px;
    transition: all 0.6s, box-shadow 1.2s, opacity 0.6s, margin-top 0.6s, padding 0.1s, width 0.1s, height 0.4s;
  }

  @media screen and (min-height: 991px) {
      margin-top: 300px;
  }

  @media screen and (min-width: 1024px) and (max-height: 800px){
      margin: 40px 0;
   }

   @media screen and (min-width: 1024px) and (max-height: 800px) and (min-height: 601px){
      margin: 80px 0;
   }
`;