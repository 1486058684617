import { SignUpFormValues } from './NewUserForm';
import { FormErrors } from '../../Hooks/useForm';
import { EmailAvailability } from "../../Hooks/useEmailVerification";

interface NewUserFormValidationConfig {
  values: SignUpFormValues;
  isEmailAvailable: EmailAvailability;
}
export const validate = (config: NewUserFormValidationConfig): FormErrors<SignUpFormValues> => {
  const { values, isEmailAvailable } = { ...config };
  let errors: FormErrors<SignUpFormValues> = {}
  if (!values.firstName) errors.firstName = 'signup.formErrors.fName';
  if (!values.lastName) errors.lastName = 'signup.formErrors.lName';

  if (!values.companyName) errors.companyName = 'signup.formErrors.coName';

  if (!values.email) errors.email = 'signup.formErrors.email.blank';
  if (values.email && isEmailAvailable === EmailAvailability.Unavailable) errors.email = 'signup.formErrors.email.unique';

  if (!values.password) errors.password = 'signup.formErrors.password.blank';
  if (values.password && values.password.length < 8) errors.password = 'signup.formErrors.password.length';
  if (values.password !== values.confirmPassword) errors.confirmPassword = 'signup.formErrors.confirmPassword.match';
  if (!values.confirmPassword) errors.confirmPassword = 'signup.formErrors.confirmPassword.blank';
  if (!values.termsAndConditions) errors.termsAndConditions = 'signup.formErrors.termsAndConditions';
  
  return errors;
}