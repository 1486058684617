import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ConcreteColors } from "hcss-components";
import {
  StyledFormGroup,
  StyledControlLabel,
  StyledFormControl,
  StyledHelpBlock,
} from "../../../FormComponents/StyledFormComponents";
import { LargePrimaryButton } from "../../../FormComponents/StyledButtons";
import {
  handleAnimateShow,
  handleAnimateHide,
  AnimatedComponentStatus,
  AnimatedComponentProps,
} from "../LoginPageAnimation";

interface ResetPasswordUsernameForm {
  model: any;
  isBlurred: boolean;
  isActiveForm: boolean;
  username: string;
  error: boolean;
  setUsername: (username: string) => void;
  setError: (error: boolean) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const ResetPasswordUsernameForm = ({
  username,
  error,
  setError,
  setUsername,
  onSubmit,
  ...props
}: ResetPasswordUsernameForm) => {
  const [status, setStatus] = React.useState<AnimatedComponentStatus>(
    AnimatedComponentStatus.Hidden
  );

  const [usernameFieldFocused, setUsernameFieldFocused] =
    React.useState<boolean>(false);
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement & any>();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.enterKeyHint = "next";
      inputRef.current.inputMode = "email";
    }
  }, [inputRef.current]);

  const handleUsernameFocusFormControl = (event: any) => {
    setUsernameFieldFocused(true);
  };
  const handleUsernameBlurFormControl = (event: any) => {
    setUsernameFieldFocused(false);
  };
  const handleUsernameChange = (event: any) => {
    setUsername(event.currentTarget.value);
    if (error) setError(false);
  };

  React.useEffect(() => {
    if (props.isActiveForm) {
      handleAnimateShow(status, 20, setStatus);
    } else {
      handleAnimateHide(status, 425, setStatus);
    }
  }, [props.isActiveForm]);

  if (!props.isActiveForm) return null;
  return (
    <Form
      hidden={!props.isActiveForm}
      onSubmit={onSubmit}
      status={status}
      blur={props.isBlurred}
    >
      <StyledFormGroup
        focused={usernameFieldFocused}
        validationState={error ? "error" : null}
      >
        <StyledControlLabel>{t("loginForm.username")}</StyledControlLabel>
        <StyledFormControl
          className="user-input"
          type="text"
          inputRef={(input) => (inputRef.current = input)}
          onChange={handleUsernameChange}
          onFocus={handleUsernameFocusFormControl}
          onBlur={handleUsernameBlurFormControl}
          name="resetPasswordInput"
          value={username}
          required
        />
        {error && (
          <StyledHelpBlock>{t("forgotForm.errorNotFound")}</StyledHelpBlock>
        )}
      </StyledFormGroup>
      <input type="hidden" name="username" value="" />
      <input type="hidden" name="password" value="" />
      <input type="hidden" name="type" value="resetpassword" />
      <input
        type="hidden"
        name={props.model.antiForgery.name}
        value={props.model.antiForgery.value}
      />
      <Info>{t("forgotForm.instructions")}</Info>
      <LargePrimaryButton
        type="submit"
        title={t("forgotForm.submitBtnLabel")}
        style={{ margin: "36px auto" }}
      >
        {t("forgotForm.submitBtnLabel")}
      </LargePrimaryButton>
    </Form>
  );
};

export default ResetPasswordUsernameForm;
const Form = styled.form<AnimatedComponentProps>`
  display: ${(props) =>
    props.status === AnimatedComponentStatus.Hidden ? "none" : "block"};

  width: 100%;
  height: 100%;

  background: #ffffff;
  transition: 0.2s;
  filter: blur(${(props) => (props.blur ? 5 : 0)}px);
  z-index: 6;
`;

const Info = styled.p`
  margin-top: -8px;
  margin-bottom: 0;
  font-weight: 400;
  width: 100%;
  color: ${ConcreteColors.gray600};

  @media screen and (max-width: 575px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 1.35rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 1.3rem;
  }
`;
