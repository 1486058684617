import styled from "styled-components";
import { ConcreteColors } from "hcss-components";

export const SectionHeading = styled.h1`
  margin: 0 0 6px;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 575px) {
    font-size: 2.6rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 2.4rem;
  }
`;

export const SectionSubHeading = styled.h3`
  margin: 0 0 8px;
  font-weight: 400;
  text-align: center;
  color: ${ConcreteColors.gray500};

  @media screen and (max-width: 575px) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 1.5rem;
  }
`;