import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ConcreteColors,
  Checkbox,
} from "hcss-components";
import styled from "styled-components";

export interface StyledFormComponentProps {
  focused: boolean;
}

/* STYLED FORM COMPONENTS */
export const StyledFormGroup = styled(FormGroup)<StyledFormComponentProps>`
  margin-bottom: 53px;

  & > .control-label {
    color: ${(props) => (props.focused ? ConcreteColors.blue200 : "#000000")};
  }

  &.has-error {
    & > .control-label {
      color: ${ConcreteColors.red200};
    }

    & .form-control,
    & .PhoneInput,
    & > .toggle-password-visibility-button {
      border-bottom-color: ${ConcreteColors.red200};
      background: ${ConcreteColors.red100};
      box-shadow: none;
      color: ${ConcreteColors.red400};

      &:focus {
        box-shadow: none;
      }
    }

    & > .help-block {
      color: ${ConcreteColors.red300};
    }
  }

  &.has-success {
    & .form-control,
    & .PhoneInput,
    & > .toggle-password-visibility-button {
      border-bottom-color: ${ConcreteColors.gray400};
      background: none;
      box-shadow: none;
      color: #404040;

      &:focus {
        border-bottom-color: ${ConcreteColors.blue200};
        box-shadow: none;
        color: #000000;
      }
    }

    & > .help-block {
      color: ${ConcreteColors.green300};
    }
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 36px;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    margin-bottom: 34px;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 32px;
  }

  transition: 0.2s;
`;

export const StyledControlLabel = styled(ControlLabel)`
  font-weight: 600;
  text-transform: uppercase;
  transition: color 0.2s;

  @media screen and (max-width: 575px) {
    margin-bottom: 2px;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    margin-bottom: 1px;
    font-size: 1.4rem;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
    font-size: 1.2rem;
  }
`;

export const StyledFormControl = styled(FormControl)`
  position: relative;
  padding: 0 0 1px 0;

  background: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid ${ConcreteColors.gray400};
  border-left: none;
  border-radius: 0;
  box-shadow: none;

  font-size: 1.5rem;
  color: #404040;

  transition: background 0.3s, border-color 0.2s, color 0.2s;

  @media screen and (max-width: 575px) {
    height: 32px;
    font-size: 1.8rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    height: 30px;
    font-size: 1.7rem;
  }

  @media screen and (min-width: 992px) {
    height: 28px;
    font-size: 1.5rem;
  }

  &:focus {
    padding: 0 0 0 0;
    background: none;
    border-bottom: 2px solid ${ConcreteColors.blue200};
    box-shadow: none;

    color: #000000;
  }
`;

export const StyledHelpBlock = styled(HelpBlock)`
  font-size: 1.3rem;
  @media screen and (max-width: 575px) {
    margin: 3px 0 -22px 0;
    font-size: 1.4rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    margin: 2px 0 -21px 0;
    font-size: 1.35rem;
  }

  @media screen and (min-width: 992px) {
    margin: 2px 0 -20px 0;
    font-size: 1.3rem;
  }
`;

export const TogglePasswordVisibility = styled.button<StyledFormComponentProps>`
  display: inline-block;
  width: 24px;
  padding-right: 2px;
  vertical-align: top;
  margin: 0 !important;
  background-color: white;
  border-left: none;
  border-right: none;
  border-top: none;

  border-bottom-color: ${(props) =>
    props.focused ? ConcreteColors.blue200 : ConcreteColors.gray400};
  border-bottom-style: solid;
  border-bottom-width: ${(props) => (props.focused ? "2px" : "1px")};

  text-align: right;
  line-height: normal;

  color: ${ConcreteColors.gray500};
  cursor: pointer;

  transition: background 0.3s, border-color 0.2s, color 0.2s;

  &:hover {
    color: ${ConcreteColors.blue200};
  }

  @media screen and (max-width: 575px) {
    height: 32px;
    padding-top: 5px;
    width: 35px;
    font-size: 1.7rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    height: 30px;
    padding-top: 5px;

    font-size: 1.6rem;
  }

  @media screen and (min-width: 992px) {
    height: 28px;
    padding-top: 4px;

    font-size: 1.5rem;
  }
`;

export interface StyledPasswordInputProps {
  hideVisibilityToggle: boolean;
}
export const StyledPasswordInput = styled(
  StyledFormControl
)<StyledPasswordInputProps>`
  display: inline-block;
  width: ${(props) =>
    props.hideVisibilityToggle ? "100%" : "calc(100% - 24px)"};

  @media screen and (max-width: 575px) {
    width: ${(props) =>
      props.hideVisibilityToggle ? "100%" : "calc(100% - 35px)"};
  }
`;

export const StyledCheckbox = styled(Checkbox)<{ hide?: boolean }>`
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  font-size: 1.35rem;

  & > span.checkbox-hcss {
    top: 4px;
    margin: 1px 10px 1px 0;
  }
`;
