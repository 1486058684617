import React, { useLayoutEffect, useState } from "react";
import { ConcreteColors, Icon, Loading, notify } from "hcss-components";
import TextControl from "../../../FormComponents/TextControl";
import PasswordControl from "../../../FormComponents/PasswordControl";
import { StyledCheckbox } from "../../../FormComponents/StyledFormComponents";
import styled from "styled-components";
import { LoginFormWrapper } from "../Structure/LoginFormWrapper";
import {
  LargePrimaryButton,
  LargeSecondaryButton,
} from "../../../FormComponents/StyledButtons";
import {
  AnimatedComponentStatus,
  handleAnimateHide,
  handleAnimateShow,
  CanBlur,
} from "../LoginPageAnimation";
import { SectionHeading, SectionSubHeading } from "../Content/LoginTextContent";
import useForm, { FormErrors } from "../../../Hooks/useForm";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LinkButton } from "../../../FormComponents/LinkButton";
import SupportActions from "./SupportActions";

interface LoginFormValues {
  username: string;
  password: string;
  rememberMe: boolean;
}

enum LoginStep {
  Username,
  Password,
}

interface LoginFormProps extends CanBlur {
  /** JSON containing tokens from IdentityServer */
  model: any;
  /** Determines whether or not form is visible to user */
  isActive: boolean;
  /** Optional heading above login form */
  heading?: string;
  /** Option subheading above login form. Should be used in conjunction with the heading, not by itself. */
  subHeading?: string;
  /** Optional use of "Remember me" checkbox */
  useKeepSignedInOption?: boolean;
  /** Label above the username field */
  userNameFieldLabel?: string;
  /** Show button for new user sign up, underneath login button. Defaults to false. */
  useSignUpButton?: boolean;
  /** Callback triggered when user requests to log in. */
  onLoginRequested?: () => void;
  /** Callback triggered when new user sign up requested. Should be used in conjunction with "useSignUpButton" */
  onNewUserSignUpRequested?: () => void;
  numOfAltLoginMethods: number;
}
const LoginForm: React.FC<LoginFormProps> = props => {
  const [status, setStatus] = useState<AnimatedComponentStatus>(
    AnimatedComponentStatus.Hidden
  );
  const [loginStep, setLoginStep] = useState(LoginStep.Username);
  const [isCheckingExternalUser, setIsCheckingExternalUser] = useState(false);
  const [isSupportLogin, setIsSupportLogin] = useState<boolean>(false);
  const [model] = useState<any>(props.model);
  const [error, setError] = useState<any>(model.errorMessage);
  const { t } = useTranslation();

    useLayoutEffect(() => {
    if (props.isActive) handleAnimateShow(status, 20, setStatus);
    else handleAnimateHide(status, 425, setStatus);
  }, [props.isActive]);

  const handleSignIn = (values: LoginFormValues, event: any) => {
    if (props.onLoginRequested) props.onLoginRequested();
    event.currentTarget.submit();
  };

  const validate = (values: LoginFormValues) => {
    let validationStates: FormErrors<LoginFormValues> = {};
    if (!values.username)
      validationStates.username = t("loginForm.errorBlankField");
    if (!values.password)
      validationStates.password = t("loginForm.errorBlankField");
    return validationStates;
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetTouched,
    handleBlur,
  } = useForm<LoginFormValues>({
    onSubmit: handleSignIn,
    validate: validate,
    initialValues: {
      username: model.loginHint,
      password: "",
      rememberMe: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  if (error) {
    notify("danger", t("error"), error);
    setError(null);
  }

  // Optional heading above form
  const renderHeading = (): JSX.Element | null => {
    if (props.heading)
      return (
        <SectionHeading className="heading">{props.heading}</SectionHeading>
      );
    return null;
  };

  // Optional sub-heading above form, underneath heading
  const renderSubHeading = (): JSX.Element | null => {
    if (props.heading)
      return (
        <SectionSubHeading
          className="subheading"
          style={{ margin: "6px 0 36px 0" }}
        >
          {props.subHeading}
        </SectionSubHeading>
      );
    return null;
  };

  // Optional keep-signed-in checkbox underneath password field
  const renderKeepSignedInOption = (): JSX.Element | null => {
    if (props.useKeepSignedInOption)
      return (
        <StyledCheckbox
          name="rememberMe"
          hide={loginStep === LoginStep.Username}
          checked={values.rememberMe}
          onClick={handleChange}
          value={values.rememberMe ? "true" : "false"}
        >
          {t("loginForm.rememberMe")}
        </StyledCheckbox>
      );
    return null;
  };

  // Anti forgery hidden field
  const AntiForgery = (): JSX.Element => {
    return (
      <input
        type="hidden"
        name={props.model.antiForgery.name}
        value={props.model.antiForgery.value}
      />
    );
  };

  // Optional new user sign up option
  const renderSignUpButton = (): JSX.Element | null => {
    if (props.useSignUpButton)
      return (
        <LargeSecondaryButton
          className="signup-button"
          type="button"
          title="Sign up for free"
          onClick={props.onNewUserSignUpRequested}
          style={{ margin: "0 auto 40px" }}
        >
          Sign up for free
        </LargeSecondaryButton>
      );
    return null;
  };

  const handleNextButtonClick = async () => {
    if (errors.username || !values.username) return;
    setIsCheckingExternalUser(true);
    if (isSupportLogin) {
      setLoginStep(LoginStep.Password);
    } else {
      try {
        const { isExternalUser, text } = await checkIfExternalUser();
        if (isExternalUser) {
          const provider = model.externalAuth.find(
            (x: { displayName: string; authenticationScheme: string }) =>
              x.displayName === text
          );
          window.location.href = `/external/externallogin?${model.returnUrl}`;
        } else {
          setLoginStep(LoginStep.Password);
        }
      } finally {
        setIsCheckingExternalUser(false);
      }
    }
  };

  const checkIfExternalUser = async (): Promise<{
    isExternalUser: boolean;
    text: string;
  }> => {
    const response = await axios.get(
      "/external/IsExternalUser?username=" +
        window.encodeURIComponent(values.username)
    );

    return response.data;
  };

  const renderButton = () => {
    if (loginStep === LoginStep.Username) {
      return (
        <NextButton
          isLoading={isCheckingExternalUser}
          onClick={handleNextButtonClick}
        />
      );
    }
    return <LoginButton />;
  };

  const backToUsername = (e: any) => {
    e.preventDefault();
    resetTouched("password");
    setLoginStep(LoginStep.Username);
  };

  return (
    <LoginFormWrapper
      className="login-form-wrapper"
      status={status}
      blur={props.blur}
      numOfAltLoginMethods={props.numOfAltLoginMethods}
    >
      <form
        className="login-form"
        method="POST"
        action={props.model.loginUrl}
        onSubmit={handleSubmit}
      >
        {renderHeading()}
        {renderSubHeading()}

        <BackButtonContainer
          style={{
            position: loginStep === LoginStep.Username ? "absolute" : undefined,
            left: -1000000,
            top: -1000000,
          }}
        >
          <LinkButton
            data-testid="back-button"
            onClick={backToUsername}
            type="button"
          >
            <Icon name="angle-left" margin="right" />
            {values.username}
          </LinkButton>
        </BackButtonContainer>

        <TextControl
          name="username"
          label={props.userNameFieldLabel || t("loginForm.username")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          enterKeyHint="next"
          inputMode="email"
          value={values.username}
          hide={loginStep === LoginStep.Password}
          message={
            touched.username && errors.username ? errors.username : undefined
          }
          validationState={
            touched.username && errors.username ? "error" : undefined
          }
        />

        <PasswordControl
          name="password"
          label={t("loginForm.password")}
          handleChange={handleChange}
          visible={loginStep === LoginStep.Password}
          focus={loginStep === LoginStep.Password}
          handleBlur={handleBlur}
          value={values.password}
          message={
            touched.password && errors.password ? errors.password : undefined
          }
          validationState={
            touched.password && errors.password ? "error" : undefined
          }
        />

        {renderKeepSignedInOption()}

        <AntiForgery />

        {renderButton()}
        {renderSignUpButton()}
      </form>
      <SupportActions onRequestSupportLogin={() => setIsSupportLogin(true)} />
    </LoginFormWrapper>
  );
};
export default LoginForm;

const LoginButton = () => {
  const { t } = useTranslation();
  return (
    <LargePrimaryButton
      className="login-button"
      type="submit"
      title={t("loginBtn.title")}
    >
      <Icon name="lock" margin="right" />
      &nbsp;&nbsp;{t("loginBtn.label")}
    </LargePrimaryButton>
  );
};

interface NextButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick: Function;
}

const NextButton = ({
  isLoading = false,
  isDisabled = false,
  onClick,
}: NextButtonProps) => {
  const { t } = useTranslation();
  const handleOnClick = (e: any) => {
    if (isDisabled) return;
    e.preventDefault();
    if (!isLoading) onClick();
  };
  return (
    <LargePrimaryButton
      className="login-button"
      onClick={handleOnClick}
      type="submit"
      title={t("loginBtn.title")}
    >
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <Icon name="arrow-right" margin="right" />
          &nbsp;&nbsp; {t("nextBtn.title")}
        </>
      )}
    </LargePrimaryButton>
  );
};

const BackButtonContainer = styled.div`
  margin-bottom: 1rem;
  & > a {
    color: ${ConcreteColors.blue200};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:focus,
    &:hover,
    &:active {
      color: ${ConcreteColors.blue300};
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 1.3rem;
  }
`;
