//Type declaration for adding the enterkeyhint to the input
import "react";
import React, { useState } from "react";
import PhoneNumberInput from "react-phone-number-input";
import {
  StyledFormGroup,
  StyledControlLabel,
  StyledHelpBlock,
} from "./StyledFormComponents";
import { FormControlProps } from "./FormControlProps";
import styled from "styled-components";
import { ConcreteColors } from "hcss-components";
declare module "react" {
  interface InputHTMLAttributes<T> extends React.HTMLAttributes<T> {
    enterkeyhint?: string;
  }
}

const countries = ["US", "FR", "CA", "AU"];

interface Props extends FormControlProps {
  onChange: (value: string) => void;
  disabled?: boolean;
}

type PhoneControlProps = Omit<Props, "handleChange">;

export const PhoneControl = (props: PhoneControlProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFieldFocused = () => setIsFocused(true);

  const phoneInputRef = React.forwardRef<HTMLInputElement>((props, ref) => {
    return <input {...props} inputMode="tel" enterkeyhint="done" ref={ref} />;
  });

  const phoneInputProps = {
    onChange: props.onChange,
    value: props.value,
    name: props.name,
    disabled: props.disabled,
    autoFocus: true,
    className: "form-control",
  };

  const handleFieldBlurred = (event: any) => {
    setIsFocused(false);
    props.handleBlur(event);
  };

  return (
    <StyledFormGroup
      id={`${props.name}-formgroup`}
      focused={isFocused}
      validationState={props.validationState}
      style={{
        paddingTop: "3rem",
        marginBottom: "7.75rem",
      }}
    >
      <StyledControlLabel>{props.label}</StyledControlLabel>
      <StyledPhoneNumberInput
        className="user-input"
        onChange={props.onChange}
        onFocus={handleFieldFocused}
        onBlur={handleFieldBlurred}
        name={props.name}
        value={props.value}
        countries={countries}
        defaultCountry={countries[0]}
        numberInputProps={phoneInputProps}
        disabled={props.disabled}
      />
      {props.message && <StyledHelpBlock>{props.message}</StyledHelpBlock>}
    </StyledFormGroup>
  );
};

const StyledPhoneNumberInput = styled(PhoneNumberInput)`
  :root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0em;
    --PhoneInputCountrySelectArrow-width: 0em;
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: inherit;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
  }

  .PhoneInput {
    display: flex;
    align-items: center;
  }

  .PhoneInputInput {
    flex: 1;

    min-width: 0;
  }

  .PhoneInputCountryIcon {
    width: 42px;
    height: 20px;
  }

  .PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
  }

  .PhoneInputCountryIcon--border {
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);

    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor);
  }

  .PhoneInputCountryIconImg {
    display: block;

    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
  }

  .PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: "";
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus);
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }

  border-bottom: 1px solid ${ConcreteColors.gray400};

  &.PhoneInput {
    display: flex;
  }

  &.PhoneInput--focus {
    padding: 0 0 0 0;
    background: none;
    border-bottom: 2px solid ${ConcreteColors.blue200};
    box-shadow: none;

    color: #000000;
  }

  .PhoneInputInput {
    position: relative;
    padding: 0 0 1px 0;

    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;

    font-size: 1.5rem;
    color: #404040;

    transition: background 0.3s, border-color 0.2s, color 0.2s;

    @media screen and (max-width: 575px) {
      height: 32px;
      font-size: 1.8rem;
    }

    @media screen and (min-width: 576px) and (max-width: 991px) {
      height: 30px;
      font-size: 1.7rem;
    }

    @media screen and (min-width: 992px) {
      height: 28px;
      font-size: 1.5rem;
    }
  }

  .PhoneInputCountry:after {
    list-style: none;
    content: "|";
    margin-left: 0.35em;
    color: ${ConcreteColors.gray400};
  }
`;
