import React from "react";
import styled from "styled-components";

const IdentityFooter = () => {
    const today = new Date();
    const year = today.getFullYear();

    return (
        <FooterContainer>
            <a href="https://www.hcss.com/" style={{ fontSize: '13px' }}>
                &copy; {year} HCSS
            </a>
            &nbsp;{'\u2022'}&nbsp;
            <a href="https://www.hcss.com/privacy-policy/" style={{ fontSize: '13px' }}>
                Privacy Policy
            </a>
            &nbsp;{'\u2022'}&nbsp;
            <a href="https://www.hcss.com/terms-of-service/" style={{ fontSize: '13px' }}>
                Terms of Service
            </a>
        </FooterContainer>
    );
};

export default IdentityFooter;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 20px;
  font-size: 1.4rem;

  @media screen and (max-width: 575px) {
    width: 90%;
  }

   @media screen and (min-height: 991px) {
      margin-bottom: 50px ;
`;