import { ConcreteColors } from "hcss-components";
import React from "react";
import styled from "styled-components";
import identityModelService from "../../../../services/IdentityModelService";

interface AnnouncementProps {
  /** JSON containing tokens from IdentityServer */
  model: any;
  blur: boolean;
}

export const Announcement: React.FC<AnnouncementProps> = ({ model, blur }) => {
  const [message] = React.useState<string>(model.announcementMsg);

  return (
    <AnnouncementWrapper
      visible={!!message}
      isSubcontractorForm={identityModelService.subcontractor}
      blur={blur}
    >
      {message}
    </AnnouncementWrapper>
  );
};

interface WrapperProps {
  visible: boolean;
  isSubcontractorForm: boolean;
  blur: boolean;
}

const AnnouncementWrapper = styled.div<WrapperProps>`
  position: relative;

  transition: 0.2s;
  transform: translateZ(0);
  background: ${ConcreteColors.yellow100};
  padding: 10px;
  border-radius: 3px;
  margin-top: ${(props) =>
    props.visible ? (props.isSubcontractorForm ? "45px" : "90px") : "45px"};
  filter: blur(${(props) => (props.blur ? 5 : 0)}px);
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  @media screen and (max-width: 575px) and (min-height: 801px) {
    margin-top: 120px;
  }
  margin-bottom: 25px;
`;
