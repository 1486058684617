import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "hcss-components";
import { TermsAndConditionsContent } from "./TermsAndConditionsContent";
import LoadingOverlay from "../Loaders/LoadingOverlay";

interface TermsAndConditionsModalProps {
  show: boolean;
  onHide: () => void;
  onAgreeAndClose: () => void;
}

const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = (
  props: TermsAndConditionsModalProps
) => {
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(false);

  const handleAgreeAndClose = () => {
    props.onAgreeAndClose();
  };

  useEffect(() => {
    if (props.show) setIsLoadingContent(true);
  }, [props.show]);

  const setIsLoading = (isLoading: boolean) => {
    setIsLoadingContent(isLoading);
  };

  return (
    <StyledModalDialog
      className="confirm-collaboration-dialog"
      show={props.show}
      onHide={props.onHide}
    >
      <LoadingOverlay isActive={isLoadingContent} />
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <TermsAndConditionsContent setIsLoading={setIsLoading} />
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="agree-and-close-terms-btn"
          hcssStyle="Theme"
          onClick={handleAgreeAndClose}
        >
          Agree & Close
        </Button>
      </Modal.Footer>
    </StyledModalDialog>
  );
};

export default TermsAndConditionsModal;

export const StyledModalDialog = styled(Modal)`
  .modal-content {
    position: relative;
    padding: 5px 10px 10px 10px;
    box-shadow: 0 4px 20px -6px #000000;

    & > .modal-header {
      align-items: flex-start;
    }
  }
`;
