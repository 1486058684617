import React, { useEffect, useState, useRef } from "react";
import {
	LoginPage,
	Container,
	HCSSLogo,
	LoginSupportLinks,
	LoginForm,
	ResetPasswordForm,
	PhoneLoginForm,
	OtherLoginMethods,
	Announcement
} from "../LoginComponents/";
import LoadingOverlay from "../Loaders/LoadingOverlay";
import {
	LoginPageDisplayStatus,
	AnimatedComponentStatus,
	handleAnimateShow,
} from "../LoginComponents/LoginPageAnimation";
import identityModelService from "../../../services/IdentityModelService";
import styled from "styled-components";
import { LoginTypes } from "../LoginComponents/Content/LoginTypes";
import IdentityFooter from "../LoginComponents/Content/Footer";

const BasicLogin: React.FC = () => {
	const loginForm = useRef<LoginPageDisplayStatus>(LoginPageDisplayStatus.RenderingLoginForm);
	const [status, setStatus] = useState<LoginPageDisplayStatus>(LoginPageDisplayStatus.Initial);
	const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);
	const [containerStatus, setContainerStatus] = useState<AnimatedComponentStatus>(
		AnimatedComponentStatus.Hidden
	);
	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [isPhoneLogin, setIsPhoneLogin] = useState<boolean>(identityModelService.defaultPhoneLogin);
	const containerBackground = "#ffffff";
	
	useEffect(() => {
		if (LoginPageDisplayStatus.RenderingContainer) handleAnimateShow(containerStatus, 10, setContainerStatus);
	}, [status]);

	useEffect(() => {
		setTimeout(() => setStatus(LoginPageDisplayStatus.RenderingContainer), 400);
		if (isPhoneLogin) setTimeout(() => setStatus(LoginPageDisplayStatus.RenderingPhoneLoginForm), 480);
		else setTimeout(() => setStatus(LoginPageDisplayStatus.RenderingLoginForm), 480);
	}, [false]);

	const enableLoader = () => setShowLoader(true);
	const disableLoader = () => setShowLoader(false);

	const handleToggleLoginType = () => {
		setStatus(LoginPageDisplayStatus.FormTransition);
		if (status === LoginPageDisplayStatus.RenderingLoginForm) {
			setTimeout(() => setStatus(LoginPageDisplayStatus.RenderingPhoneLoginForm), 200);
			loginForm.current = LoginPageDisplayStatus.RenderingPhoneLoginForm;
			setIsPhoneLogin(true);
		} else if (status === LoginPageDisplayStatus.RenderingPhoneLoginForm) {
			setTimeout(() => setStatus(LoginPageDisplayStatus.RenderingLoginForm), 200);
			loginForm.current = LoginPageDisplayStatus.RenderingLoginForm;
			setIsPhoneLogin(false);
		}
	};

	const handlePasswordResetRequest = () => {
		setStatus(LoginPageDisplayStatus.FormTransition);
		setTimeout(() => setStatus(LoginPageDisplayStatus.RenderingResetForm), 200);
		setIsResettingPassword(true);
	};
	const cancelPasswordResetRequest = () => {
		setStatus(LoginPageDisplayStatus.FormTransition);
		setTimeout(() => setStatus((x) => loginForm.current), 200);
		setIsResettingPassword(false);
	};

	return (
		<BasicLoginPage className="login-page">
			<Container status={containerStatus} background={containerBackground}>
				<HCSSLogo blur={showLoader} />
				<Announcement model={identityModelService} blur={showLoader} />
				<FormWrapper>
					<LoginForm
						model={identityModelService}
						isActive={status === LoginPageDisplayStatus.RenderingLoginForm}
						blur={showLoader}
						onLoginRequested={enableLoader}
						useKeepSignedInOption
						numOfAltLoginMethods={(identityModelService.allowedLoginTypes as LoginTypes[]).length - 1}
					/>

					<PhoneLoginForm
						model={identityModelService}
						isActive={status === LoginPageDisplayStatus.RenderingPhoneLoginForm}
						blur={showLoader}
						onLoginRequested={enableLoader}
					/>

					<ResetPasswordForm
						model={identityModelService}
						onProcessRequestStart={enableLoader}
						onProcessRequestEnd={disableLoader}
						onCancel={cancelPasswordResetRequest}
						blur={showLoader}
						isActive={status === LoginPageDisplayStatus.RenderingResetForm}
						isMobile={identityModelService.isMobile}
					/>

				</FormWrapper>

				<OtherLoginMethods
					toggleLoginType={handleToggleLoginType}
					blur={showLoader}
					isActive={
						status === LoginPageDisplayStatus.RenderingLoginForm ||
						status === LoginPageDisplayStatus.RenderingPhoneLoginForm
					}
					isPhoneLogin={isPhoneLogin}
					allowedLoginTypes={identityModelService.allowedLoginTypes}
				/>

				<LoginSupportLinks
					onRequestPasswordReset={handlePasswordResetRequest}
					userIsResettingPassword={isResettingPassword}
					blur={showLoader}
					isActive={
						status === LoginPageDisplayStatus.RenderingLoginForm ||
						status === LoginPageDisplayStatus.RenderingResetForm ||
						status === LoginPageDisplayStatus.FormTransition ||
						status === LoginPageDisplayStatus.RenderingPhoneLoginForm
					}
					numOfAltLoginMethods={(identityModelService.allowedLoginTypes as LoginTypes[]).length - 1}
					isMobile={identityModelService.isMobile}
				/>
				<LoadingOverlay isActive={showLoader} />
			</Container>
			<IdentityFooter />
		</BasicLoginPage>
	);
};
export default BasicLogin;



const BasicLoginPage = styled(LoginPage)`
	flex-direction: column;
	justify-content: space-between;
	
	@media screen and (max-width: 576px) {
		align-items: flex-start;
		background: #ffffff;
	}

	@media screen and (min-width: 576px) and (max-width: 992px) and (max-height: 490px) {
		align-items: flex-start;
		padding: 10px 0;
	}

	@media screen and (min-width: 576px) and (max-width: 992px) and (min-height: 490px) {
		align-items: center;
	}

	@media screen and (min-width: 992px) and (max-height: 490px) {
		align-items: flex-start;
		padding: 10px 0;
	}

	@media screen and (min-width: 992px) and (min-height: 490px) {
		align-items: center;
	}
`;

const FormWrapper = styled.div`
	position: relative;
	min-height: 205px;
`;
