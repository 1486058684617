import styled from "styled-components";

interface LoadingSpinnerProps {
  /** Determines whether or not the loader will be visible */
  visible: boolean;
  /** Allows you to set a custom opacity value when the loader is visible */
  visibleOpacity?: number;
  /** Allows you to implement an inverted-style loader */
  inverted?: boolean;
  /** Allows you to override the default background fill color */
  background?: string;
}
export const LoadingSpinner = styled.div<LoadingSpinnerProps>`
  position: absolute;
  width: 10em;
  height: 10em;
  
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
  opacity: ${props => props.visible ? 
    props.visibleOpacity || "0.8" 
    : "0.0"};

  font-size: 6px;
  text-indent: -99999em;
  color: ${props => props.inverted ? "#ffffff" : "#009639"};
  transition: opacity 0.4s;

  z-index: 1;

  &,
  &:before,
  &:after {
    border-radius: 50%;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
  }

  &:before {
    top: -0.1em;
    left: -0.1em;
    width: 5.2em;
    height: 10.2em;

    background: ${props => props.background || "#ffffff"};
    border-radius: 10.2em 0 0 10.2em;
    transform-origin: 5.2em 5.1em;

    animation: load2 1s infinite ease .3s;
  }

  &:after {
    top: -0.1em;
    left: 5.1em;
    width: 5.2em;
    height: 10.2em;

    background: ${props => props.background || "#ffffff"};
    border-radius: 0 10.2em 10.2em 0;
    transform-origin: 0px 5.1em;
    
    animation: load2 1s infinite ease;
  }

  @-webkit-keyframes load2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes load2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;