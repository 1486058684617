import { useState } from "react";

interface FormValues {
  [field: string]: any;
}

interface FormConfig<T> {
  onSubmit: (values: T, event: any) => void;
  validate: (values: T) => FormErrors<T>;
  initialValues: T;
  validateOnBlur: boolean;
  validateOnChange: boolean;
}

export type FormErrors<Values extends FormValues> = Partial<Values>;
export type TouchedFields<Values extends FormValues> = Partial<Values>;

interface FormState<T> {
  handleChange: (e: any) => void;
  handleSubmit: (e: any) => Promise<void>;
  handleBlur: (e: any) => void;
  handleReset: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  resetTouched: (field: string) => void;
  values: T;
  errors: FormErrors<T>;
  touched: TouchedFields<T>;
  isSubmitting: boolean;
  revalidate: () => void;
}

function useForm<Values extends FormValues>(
  config: FormConfig<Values>
): FormState<Values> {
  const { validateOnChange, validateOnBlur, initialValues } = config;

  const [values, setValues] = useState<Values>(config.initialValues);
  const [errors, setErrors] = useState<FormErrors<Values>>({});
  const [touched, setTouched] = useState<TouchedFields<Values>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: any) => {
    if (event) {
      event.preventDefault();
    }

    setIsSubmitting(true);

    let t: any = {};
    for (let k of Object.keys(values)) {
      t[k] = true;
    }
    setTouched(t);

    const errors = config.validate(values);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      await config.onSubmit(values, event);
      //event.currentTarget.submit();
    }

    setIsSubmitting(false);
  };

  const handleReset = () => {
    setTouched({});
    setValues(initialValues);
    setErrors({});
  };

  const handleBlur = (event: any) => {
    if (event.persist) event.persist();

    setTouched(values => ({
      ...values,
      [event.target.name]: true,
    }));

    if (validateOnBlur) {
      setErrors(config.validate(values));
    }
  };

  const handleChange = (event: any) => {
    if (event.persist) event.persist();

    let newValues: Values;
    if (event.target && event.target.type && event.target.type === "checkbox") {
      newValues = { ...values, [event.target.name]: event.target.checked };
    } else {
      newValues = { ...values, [event.target.name]: event.target.value };
    }

    setValues(newValues);

    if (validateOnChange) {
      setErrors(config.validate(newValues));
    }
  };

  const setFieldValue = (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => {
    setValues(prevState => {
      const newValues = { ...prevState, [field]: value };

      if (shouldValidate) {
        setErrors(config.validate(newValues));
      }

      return newValues;
    });
  };

  const revalidate = () => {
    setErrors(config.validate(values));
  };

  const resetTouched = (field: string) => {
    setTouched(values => ({
      ...values,
      [field]: false,
    }));
  };

  return {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    handleBlur,
    resetTouched,
    setFieldValue,
    isSubmitting,
    revalidate,
  };
}

export default useForm;
