import styled from "styled-components";

export const LoginPage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: auto;
`;

export const LoginPageContent = styled.div`
  position: relative;
  z-index: 2;
`; 