import React, { useEffect, useRef, useState } from "react";
import { Icon } from "hcss-components";
import {
  StyledFormGroup,
  StyledControlLabel,
  StyledPasswordInput,
  StyledHelpBlock,
  TogglePasswordVisibility,
} from "./StyledFormComponents";
import { FormControlProps } from "./FormControlProps";
import { useTranslation } from "react-i18next";

interface PasswordControlProps extends FormControlProps {
  /** Optionally hides the password visibility toggle when set to true. Defaults to false. */
  focus?: boolean;
  visible?: boolean;
  hideVisibilityToggle?: boolean;
}

const PasswordControl: React.FC<PasswordControlProps> = ({
  visible = true,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement & any>();

  const handleFieldFocused = () => setIsFocused(true);

  const handleFieldBlurred = (event: any) => {
    setIsFocused(false);
    props.handleBlur(event);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.enterKeyHint = "done";
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (visible && props.focus)
      inputRef && inputRef.current && inputRef.current.focus();
  }, [visible, props.focus]);

  return (
    <StyledFormGroup
      id={`${props.name}-formgroup`}
      focused={isFocused}
          style={{
              position: visible ? undefined : "absolute",
              left: -1000000,
              top: -1000000,
              marginBottom: "32px"
          }}
      validationState={props.validationState}
    >
      <StyledControlLabel>{props.label}</StyledControlLabel>
      <StyledPasswordInput
        className="user-input"
        type={showPassword ? "text" : "password"}
        onChange={props.handleChange}
        onFocus={handleFieldFocused}
        inputRef={(input) => (inputRef.current = input)}
        onBlur={handleFieldBlurred}
        name={props.name}
        value={props.value}
        hideVisibilityToggle={props.hideVisibilityToggle || false}
      />
      {!props.hideVisibilityToggle && (
        <TogglePasswordVisibility
          className="toggle-password-visibility-button"
          focused={isFocused}
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          title={
            showPassword
              ? t("loginForm.hidePassword")
              : t("loginForm.showPassword")
          }
        >
          <Icon name={showPassword ? "eye-slash" : "eye"}></Icon>
        </TogglePasswordVisibility>
      )}
      {props.message && <StyledHelpBlock>{props.message}</StyledHelpBlock>}
    </StyledFormGroup>
  );
};

export default PasswordControl;
