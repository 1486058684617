import React from "react";
import {
  ConcreteColors,
  Notification,
  ThemeProvider,
  themes,
} from "hcss-components";
import styled, { createGlobalStyle } from "styled-components";
import identityModelService from "./services/IdentityModelService";
import { Background } from "./components/Background";
import BasicLogin from "./components/Login/BasicLogin";
import DualLoginSignUp from "./components/Login/DualLoginSignup/";
import "react-phone-number-input/style.css";

const Main = styled.main`
  display: block;
  box-sizing: border-box;
  overflow: auto;

  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
`;

const App: React.FC = () => {
  const LoginComponent: React.ComponentType | null =
    identityModelService.subcontractor ? DualLoginSignUp : BasicLogin;

  const isIE = /*@cc_on!@*/ false || !!(document as any).documentMode;
  const backgroundColor = `radial-gradient(ellipse at center, ${ConcreteColors.gray100}, ${ConcreteColors.gray300} 112%)`;

  return (
    <Main>
      <ThemeProvider theme={themes.preConstruction}>
        <>
          <GlobalStyle renderForIE={isIE ? 1 : 0} />
          <Background backgroundColor={backgroundColor} />
          <Notification />
          <LoginComponent />
        </>
      </ThemeProvider>
    </Main>
  );
};

export default App;

interface GlobalStyleProps {
  renderForIE: number;
}
const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  body {
    font-family: ${props =>
      props.renderForIE
        ? `Arial, Helvetica, sans-serif`
        : `"Inter", Arial, Helvetica, sans-serif`};
  }
`;
