import React, { useLayoutEffect, useState } from "react";
import { Icon, notify } from "hcss-components";
import { LoginFormWrapper } from "../Structure/LoginFormWrapper";
import {
  LargePrimaryButton,
  LargeSecondaryButton,
} from "../../../FormComponents/StyledButtons";
import {
  AnimatedComponentStatus,
  handleAnimateHide,
  handleAnimateShow,
  CanBlur,
  AnimatedComponentProps,
} from "../LoginPageAnimation";
import { SectionHeading, SectionSubHeading } from "../Content/LoginTextContent";
import useForm, { FormErrors } from "../../../Hooks/useForm";
import { useTranslation } from "react-i18next";
import { PhoneControl } from "../../../FormComponents/PhoneControl";
import styled from "styled-components";
import { isPossiblePhoneNumber } from "react-phone-number-input";

interface LoginFormValues {
  phoneNumber: string;
}

interface LoginFormProps extends CanBlur {
  /** JSON containing tokens from IdentityServer */
  model: any;
  /** Determines whether or not form is visible to user */
  isActive: boolean;
  /** Optional heading above login form */
  heading?: string;
  /** Option subheading above login form. Should be used in conjunction with the heading, not by itself. */
  subHeading?: string;
  /** Optional use of "Remember me" checkbox */
  useKeepSignedInOption?: boolean;
  /** Label above the username field */
  userNameFieldLabel?: string;
  /** Show button for new user sign up, underneath login button. Defaults to false. */
  useSignUpButton?: boolean;
  /** Callback triggered when user requests to log in. */
  onLoginRequested?: () => void;
  /** Callback triggered when new user sign up requested. Should be used in conjunction with "useSignUpButton" */
  onNewUserSignUpRequested?: () => void;
}
const PhoneLoginForm: React.FC<LoginFormProps> = props => {
  const [status, setStatus] = useState<AnimatedComponentStatus>(
    AnimatedComponentStatus.Hidden
  );
  const [model] = useState<any>(props.model);
  const [error, setError] = useState<any>(model.errorMessage);

  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (props.isActive) handleAnimateShow(status, 20, setStatus);
    else handleAnimateHide(status, 425, setStatus);
  }, [props.isActive]);

  const handlePhoneControlChange = (value: string) => {
    handleChange({ target: { name: "phoneNumber", value } });
  };

  const handleSignIn = (values: LoginFormValues, event: any) => {
    if (props.onLoginRequested) props.onLoginRequested();
    event.currentTarget.submit();
  };

  const validate = (values: LoginFormValues) => {
    let validationStates: FormErrors<LoginFormValues> = {};
    if (!values.phoneNumber)
      validationStates.phoneNumber = t("loginForm.errorBlankField");
    if (values.phoneNumber && !isPossiblePhoneNumber(values.phoneNumber))
      validationStates.phoneNumber = t("loginForm.errorInvaldPhone");
    return validationStates;
  };

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useForm<LoginFormValues>({
      onSubmit: handleSignIn,
      validate: validate,
      initialValues: {
        phoneNumber: "",
      },
      validateOnBlur: true,
      validateOnChange: true,
    });

  if (error) {
    notify("danger", t("error"), error);
    setError(null);
  }

  // Optional heading above form
  const renderHeading = (): JSX.Element | null => {
    if (props.heading)
      return (
        <SectionHeading className="heading">{props.heading}</SectionHeading>
      );
    return null;
  };

  // Optional sub-heading above form, underneath heading
  const renderSubHeading = (): JSX.Element | null => {
    if (props.heading)
      return (
        <SectionSubHeading
          className="subheading"
          style={{ margin: "6px 0 36px 0" }}
        >
          {props.subHeading}
        </SectionSubHeading>
      );
    return null;
  };

  // Anti forgery hidden field
  const AntiForgery = (): JSX.Element => {
    return (
      <input
        type="hidden"
        name={props.model.antiForgery.name}
        value={props.model.antiForgery.value}
      />
    );
  };

  // Optional new user sign up option
  const renderSignUpButton = (): JSX.Element | null => {
    if (props.useSignUpButton)
      return (
        <LargeSecondaryButton
          className="signup-button"
          type="button"
          title="Sign up for free"
          onClick={props.onNewUserSignUpRequested}
          style={{ margin: "-80px auto 40px auto" }}
        >
          Sign up for free
        </LargeSecondaryButton>
      );
    return null;
  };

  return (
    <LoginFormWrapper
      className="phone-login-form-wrapper"
      status={status}
      blur={props.blur}
      numOfAltLoginMethods={1}
    >
      <form
        className="login-form"
        method="POST"
        action={props.model.loginUrl}
        onSubmit={handleSubmit}
      >
        {renderHeading()}
        {renderSubHeading()}

        <PhoneControl
          name="phoneNumber"
          label={t("loginForm.phoneNumber")}
          onChange={handlePhoneControlChange}
          value={values.phoneNumber}
          handleBlur={handleBlur}
          message={
            touched.phoneNumber && errors.phoneNumber
              ? errors.phoneNumber
              : undefined
          }
          validationState={
            touched.phoneNumber && errors.phoneNumber ? "error" : undefined
          }
        />

        <input
          name="phoneNumberInput"
          type="hidden"
          value={values.phoneNumber}
        />
        <input name="username" type="hidden" value="boi" />
        <input name="password" type="hidden" value="youthought" />

        <AntiForgery />

        <LargePrimaryButton
          className="login-button"
          type="submit"
          title={t("loginBtn.title")}
        >
          <Icon name="lock" margin="right" />
          &nbsp;&nbsp;{t("loginBtn.label")}
        </LargePrimaryButton>

        {renderSignUpButton()}
      </form>
    </LoginFormWrapper>
  );
};
export default PhoneLoginForm;
