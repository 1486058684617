import React, { useEffect, useRef } from "react";

import {
  StyledFormGroup,
  StyledControlLabel,
  StyledFormControl,
  StyledHelpBlock,
} from "../../../FormComponents/StyledFormComponents";
import { LargePrimaryButton } from "../../../FormComponents/StyledButtons";
import { useTranslation } from "react-i18next";
import {
  AnimatedComponentStatus,
  handleAnimateShow,
  handleAnimateHide,
  AnimatedComponentProps,
} from "../LoginPageAnimation";
import styled from "styled-components";
import { ConcreteColors } from "hcss-components";
import { ITwoFactorAuthModel } from "./ResetPasswordForm";
import { ResendTotpButton } from "../../../FormComponents/ResendTotpButton";

interface ResetPasswordOTPFormProps {
  model: any;
  isBlurred: boolean;
  isActiveForm: boolean;
  twoFactorAuthModel: ITwoFactorAuthModel;
  otp: string;
  error: boolean;
  setOTP: (otp: string) => void;
  setError: (error: boolean) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  userName: string;
}

//  This needs to be the page where they can enter the code which will redirect them to the actual reset password page
const ResetPasswordConfirmation = ({
  otp,
  error,
  setOTP,
  setError,
  onSubmit,
  twoFactorAuthModel,
  userName,
  ...props
}: ResetPasswordOTPFormProps) => {
  const [status, setStatus] = React.useState<AnimatedComponentStatus>(
    AnimatedComponentStatus.Hidden
  );
  const [otpFieldFocused, setOTPFieldFocused] = React.useState<boolean>(false);
  const [primaryContactMethod, setPrimaryContactMethod] =
    React.useState<string>("");
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement & any>();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.enterKeyHint = "next";
      inputRef.current.inputMode = "numeric";
    }
  }, [inputRef.current]);

  const handleOTPFocusFormControl = (event: any) => {
    setOTPFieldFocused(true);
  };
  const handleOTPBlurFormControl = (evnet: any) => {
    setOTPFieldFocused(false);
  };
  const handleOTPChange = (event: any) => {
    setOTP(event.target.value);
    if (error) setError(false);
  };

  React.useEffect(() => {
    if (props.isActiveForm) {
      handleAnimateShow(status, 20, setStatus);
    } else {
      handleAnimateHide(status, 425, setStatus);
      setPrimaryContactMethod("");
    }
  }, [props.isActiveForm]);

  React.useEffect(() => {
    if (twoFactorAuthModel.defaultProvider === "sms") {
      setPrimaryContactMethod(twoFactorAuthModel.phoneNumber);
    } else if (twoFactorAuthModel.defaultProvider === "email") {
      setPrimaryContactMethod(twoFactorAuthModel.email);
    }
  }, [twoFactorAuthModel]);

  if (props.isActiveForm) {
    return (
      <Form
        onSubmit={onSubmit}
        method="POST"
        status={status}
        blur={props.isBlurred}
        autoComplete="false"
        action="/auth/token"
      >
        <StyledFormGroup
          focused={otpFieldFocused}
          validationState={error ? "error" : null}
        >
          <StyledControlLabel>{t("forgotForm.otp")}</StyledControlLabel>
          <StyledFormControl
            className="user-input"
            type="text"
            onChange={handleOTPChange}
            onFocus={handleOTPFocusFormControl}
            onBlur={handleOTPBlurFormControl}
            inputRef={input => (inputRef.current = input)}
            name="token"
            value={otp}
            autoComplete="false"
            required
          />
          {error && (
            <StyledHelpBlock>{t("forgotForm.errorOtp")}</StyledHelpBlock>
          )}
        </StyledFormGroup>
        <Info>
          {`${t("forgotForm.otpSent")}`}
          {primaryContactMethod ? `: ${primaryContactMethod}` : ""}
        </Info>
        <input type="hidden" name="type" value="resetpassword" />
        {/* <input
          type="hidden"
          name="__RequestVerificationToken"
          value={props.model.custom.formToken}
        /> */}
        <LargePrimaryButton
          type="submit"
          title={t("forgotForm.submitBtnLabel")}
        >
          {t("forgotForm.submitBtnLabel")}
        </LargePrimaryButton>
        <ResendTotpButton
          totpType="resetpassword"
          twoFactorAuthModel={twoFactorAuthModel}
          username={userName}
        />
      </Form>
    );
  } else return null;
};

export default ResetPasswordConfirmation;

const Form = styled.form<AnimatedComponentProps>`
  display: ${props =>
    props.status === AnimatedComponentStatus.Hidden ? "none" : "block"};

  width: 100%;
  height: 100%;

  background: #ffffff;
  opacity: ${props =>
    props.status === AnimatedComponentStatus.Showing ||
    props.status === AnimatedComponentStatus.Visible
      ? 1.0
      : 0.0};
  filter: blur(${props => (props.blur ? 5 : 0)}px);
  transition: 0.2s;
  z-index: 6;
`;

const Info = styled.p`
  font-weight: 400;
  width: 100%;
  color: ${ConcreteColors.gray600};

  @media screen and (max-width: 575px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 1.35rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 1.3rem;
  }
`;
