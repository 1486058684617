import axios from "axios";
import React, { useEffect, useState } from "react";

interface TermsAndConditionsContentProps {
  setIsLoading: (isLoading: boolean) => void;
}
export const TermsAndConditionsContent: React.FC<
  TermsAndConditionsContentProps
> = (props: TermsAndConditionsContentProps) => {
  const [page, setPage] = useState<any>();

  useEffect(() => {
    const getTermsAndConditions = async () => {
      try {
        const response = await axios.get("/Account/GetTermsAndConditions");
        setPage({ __html: response.data.htmlContent });
      } finally {
        props.setIsLoading(false);
      }
    };
    getTermsAndConditions();
  }, []);

  return <div dangerouslySetInnerHTML={page} />;
};
