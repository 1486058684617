import { useEffect, useState } from "react";
import { notify } from "hcss-components";
import axios from "axios";
import { useTranslation } from "react-i18next";

export enum EmailAvailability {
  Unknown = 1,
  Unavailable = 2,
  Available = 3,
  Error = 4,
}

interface EmailVerificationModel {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  checkEmailAvailability: () => Promise<void>;
  isEmailAvailable: EmailAvailability;
}

interface EmailAvailabilityCheckResponseModel {
  data: {
    userExists: boolean;
  };
}

interface EmailVerificationConfig {
  /** JSON containing tokens from IdentityServer */
  model: any;
}
function useEmailVerification(
  config: EmailVerificationConfig
): EmailVerificationModel {
  const [email, setEmail] = useState<string>("");
  const [isEmailAvailable, setIsEmailAvailable] = useState<EmailAvailability>(
    EmailAvailability.Unknown
  );
  const { t } = useTranslation();

  // Verify email when requested
  const checkEmailAvailability = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      processCheckAvailability()
        .then(result => {
          if (result.data.userExists) {
            setIsEmailAvailable(EmailAvailability.Unavailable);
            reject();
          } else {
            setIsEmailAvailable(EmailAvailability.Available);
            resolve();
          }
        })
        .catch(() => {
          setIsEmailAvailable(EmailAvailability.Error);
          notify(
            "danger",
            t("error"),
            t("signup.formErrors.general") as string
          );
          reject();
        });
    });
  };

  const processCheckAvailability =
    (): Promise<EmailAvailabilityCheckResponseModel> => {
      let data = new FormData();
      data.append("Email", email);
      data.append("__RequestVerificationToken", config.model.antiForgery.value);

      return axios.post("/Account/IsAnExistingUser", data);
    };

  useEffect(() => {
    setIsEmailAvailable(EmailAvailability.Unknown);
  }, [email]);

  return {
    setEmail,
    checkEmailAvailability,
    isEmailAvailable,
  };
}
export default useEmailVerification;
